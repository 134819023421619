import './Price.css'

export default function Price({formData, setValue}) {
  return (
    <div className="price__wrapper">
      <p className="price__desc">От</p>
      <input
        min="0"
        placeholder={0}
        name="min-price"
        value={formData['regularPrice']['min']}
        onChange={option => setValue(option, 'regularPrice', 'min')}
        id="min-price"
        type="number"
        className="price__input input"
        max={182832}
      />
      <p className="price__desc">До</p>
      <input
        min="0"
        max="182832"
        placeholder={182832}
        name="max-price"
        value={formData['regularPrice']['max']}
        onChange={option => setValue(option, 'regularPrice', 'max')}
        id="max-price"
        type="number"
        className="price__input input"
      />
    </div>
  )
}
