import './styles/TemplateTable.css'

export default function TemplateTable({ categoryName, data, model }) {
  return (
    <table className="table-specs" style={{float: categoryName === "Дополнительные параметры" ? 'right' : 'left'}}>
      <tbody>
        <tr>
          <td colSpan="2" className="table-specs__head">{categoryName}</td>
        </tr>
           {
            categoryName === 'Основное' &&
              <tr>
                <td className="table-specs__title">Артикул</td>
                <td className="table-specs__text">{model}</td>
              </tr>
           }
        {data.map(item => {
          return (
            <tr key={item.name}>
              <td className="table-specs__title">{item.name}</td>
              <td className="table-specs__text">{item.value.join(', ')}</td>
            </tr>
        )})}
      </tbody>
    </table>
  )
}
