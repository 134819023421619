import Preview from "../Preview/Preview";
import "./styles/FromThisSeries.css"

export default function FromThisSeries({addToBasket, data}) {
  return (
    <div className="wrapper">
      <div className="series">
        {
          data.map((el) => <Preview key={el.id} id={el.id} cardData={el} addToBasket={addToBasket}/>)
        }
      </div>
    </div>
  )
}
