import './styles/ContactsPage.css'
import { YMaps, Map, Placemark, ZoomControl } from '@pbe/react-yandex-maps';
import LampsIcon from '../../UI/icons/contacts-lamps.png'
import IconPhone from '../../UI/icons/icon-phone.svg'
import IconMail from '../../UI/icons/icon-mail.svg'
import IconCalendar from '../../UI/icons/icon-calendar.svg'
import FeedbackForm from '../../components/FeedbackForm/FeedbackForm'
import {Telegram, Whatsapp} from "react-bootstrap-icons";

export default function ContactsPage() {

  const defaultState = {
    center: [54.885906, 38.054045],
    zoom: 16.66,
    // controls: []
  };

  return (
    <div className="contacts">
      <div className="contacts__title page__title">Контакты</div>
      <div className="contacts__info">
        <div className="contacts__img">
          <img src={LampsIcon} width="100%" />
        </div>
        <div className="contacts__phone">
          <div className="contacts__icon">
            <img src={IconPhone} />
          </div>
          <div className="contacts__block">
            <span className="contacts-span">Телефон:</span>
            <span className="contacts__content">+7 (926) 310-22-11</span>
          </div>
        </div>
        <div className="contacts__email">
        <div className="contacts__icon">
            <img src={IconMail} />
          </div>
          <div className="contacts__block">
            <span className="contacts-span">Почта:</span>
            <span className="contacts__content">info@miradec.ru</span>
          </div>
        </div>
        <div className="contacts__schedule">
          <div className="contacts__icon">
            <img src={IconCalendar} />
          </div>
          <div className="contacts__block">
            <span className="contacts-span">Режим работы:</span>
            <span className="contacts__content">
            Ежедневно: 10:00-19:00
            </span>
          </div>
        </div>
        <div className="contacts__social">
          <a className="contacts__social-link" href="https://t.me/Miradec"><Telegram color="#2AABEE" size={30}/></a>
          <a className="contacts__social-link" target="blank" href="https://api.whatsapp.com/send/?phone=%2B79263102211&text&type=phone_number&app_absent=0"><Whatsapp color="#25D366" size={30}/></a>
        </div>
      </div>
      <div className="contacts__action-wrapper">
        <div className="contacts__feedback">
          <FeedbackForm />
        </div>
        <div className="contacts__map">
          
          <YMaps>
            <Map 
              defaultState={defaultState}
              width="100%"
              height="100%"
            >
              
              <ZoomControl options={{ float: "right" }} />
              <Placemark geometry={[54.885419, 38.053390]} />
              
            </Map>
          </YMaps>
        </div>
      </div>
    </div>
  )
}