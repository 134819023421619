import './Preview.css';
import priceCalculation from "../../utils/priceCalculation";
import {Link} from "react-router-dom";
import {Cart} from "react-bootstrap-icons";
import calculatePercentageDifference from '../../utils/calculatePercentageDifference';
import QuantityStatus from '../QuantityStatus/QuantityStatus';
export default function Preview({id, cardData, addToBasket}) {
  const {regularPrice, specialPrice, image, name, model, manufacturerName, quantity} = cardData
  let price = priceCalculation(regularPrice)
  let discount = priceCalculation(specialPrice)
  let percentageDiscount = regularPrice && specialPrice ? Math.floor(calculatePercentageDifference(regularPrice, specialPrice)) + "%" : null

  return(
    <Link to={`/cards/${id}`} className="preview-wrapper">
      <div className="preview">
        {specialPrice && <div className="discount">{percentageDiscount}</div>}
        <img src={image} alt="" className="preview__image"/>
        <h3 className="preview__name">{name}</h3>
        <div className="preview__desc">
          <div className="preview__desc-wrapper ellipsis">
            <p className="preview__article">Артикул:</p>
            <p className="preview__article ellipsis">{model}</p>
          </div>
          <div className="preview__desc-wrapper">
            <p className="preview__article">Производитель:</p>
            <p className="preview__article ellipsis">{manufacturerName}</p>
          </div>
        </div>
        <QuantityStatus quantity={cardData.quantity}/>
        <div className="preview__to">
          <div className="preview__price">
            <span className={specialPrice !== null ? 'regular__price' : 'special__price'}>{price}</span>
            <span className="special__price">{specialPrice ? discount : null}</span>
          </div>

          <button
            type="button"
            className="btn btn-warning basket-btn"
            onClick={(event) => addToBasket(event, name, model, price, image, 1, quantity)}
          >
            <Cart size={25} color='white' className="menu__item__image" style={{ verticalAlign: 'middle', margin: 0 }}/>
          </button>

        </div>
      </div>
    </Link>

  )
}
