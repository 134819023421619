import './styles/SpecsTabs.css'
import Tab from 'react-bootstrap/Tab';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import SpecIcon from "../../../UI/icons/characteristics.svg"
import GlassIcon from "../../../UI/icons/glass.svg"
import CategoryTable from '../CategoryTable';
import LampHelper from '../../LampHelper/LampHelper';
import {cardData} from "../../../constants/cardData";

export default function SpectTabs({information, model, setIsLoading, addToBasket, name}) {
  let typePlinth = information.filter(el => el.name === 'Тип цоколя ламп')
  let valuePlinth
  if (typePlinth.length) {
    valuePlinth = typePlinth[0].value[0] // получили цоколь из описания продукта
  } else {
    valuePlinth = ''
  }

  const lampsTabVisible = valuePlinth && !name.toLowerCase().includes('Лампочка') && !name.toLowerCase().includes('лампа') 

  return (
    <section className="product-specs">
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Col>
          <Row>
            <Nav className="flex-rows">
              <Nav.Item>
                <Nav.Link eventKey="first">
                  <div className="info-item">
                    <img className="info-icon" src={SpecIcon}/>
                    <div className="info-link product-specs__title">ХАРАКТЕРИСТИКИ</div>
                  </div>
                </Nav.Link>
              </Nav.Item>
              { lampsTabVisible &&
                <Nav.Item>
                  <Nav.Link eventKey="second">
                    <div className="info-item">
                      <img className="info-icon" src={GlassIcon}/>
                      <div className="info-link product-specs__title">ЛАМПОЧКИ</div>
                    </div>
                  </Nav.Link>
                </Nav.Item>}
            </Nav>
          </Row>
          <Row sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                {/* <SpecsTable /> */}
                <CategoryTable information={information} model={model}/>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                {lampsTabVisible &&
                  <LampHelper
                    addToBasket={addToBasket}
                    data={cardData}
                    setIsLoading={setIsLoading}
                    valuePlinth={valuePlinth}
                  />}
              </Tab.Pane>
            </Tab.Content>
          </Row>
        </Col>
      </Tab.Container>

    </section>
  )
}
