import React, {useState} from 'react';
import {Modal, Button, Form} from 'react-bootstrap';
import InputMask from 'react-input-mask'; // Импорт пакета для маски
import './styles/FeedbackModal.css';
import {useNavigate} from 'react-router-dom';
import {sendMail} from "../../utils/illumination/api";
import uniqueProducts from "../../utils/getUniqueProducts";

export default function FeedbackModal({showModal, closeModal, updateBasketData}) {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    comment: '',
    products: []
  });
  const [submitted, setSubmitted] = useState(false);

  const handleFormChange = (e) => {
    const {name, value} = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    formData.products = uniqueProducts(JSON.parse(localStorage.getItem('basket')))
    formData.totalPrice = formData.products.reduce((sum, current) => sum + current.totalPrice, 0);

    sendMail(formData)
      .then(res => console.log('send ok'))
    setTimeout(() => {
      setSubmitted(true)
      setTimeout(() => {
        localStorage.clear()
        updateBasketData([])
        navigate('/') // Редирект на главную страницу через 3 секунды
      }, 3000)
    }, 1000)
  }

  return (
    <div>
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Форма обратной связи</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {submitted ? (
            <div>
              <p>Спасибо! В ближайшее время мы перезвоним.</p>
            </div>
          ) : (
            <Form onSubmit={handleFormSubmit}>
              <Form.Group controlId="formName">
                <Form.Label>Имя</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleFormChange}
                  isInvalid={!formData.name}
                />
              </Form.Group>
              <Form.Group controlId="formPhone">
                <Form.Label>Телефон</Form.Label>
                <InputMask
                  mask="+7 (999) 999-99-99" // Маска для российского номера
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleFormChange}
                  isInvalid={!formData.phone}
                >
                  {(inputProps) => <Form.Control {...inputProps} />}
                </InputMask>
              </Form.Group>
              <Form.Group controlId="formEmail">
                <Form.Label>Электронная почта</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleFormChange}
                  isInvalid={!formData.email}
                />

              <Form.Control.Feedback type="invalid">
                Пожалуйста, введите правильный email адрес.
              </Form.Control.Feedback>

            </Form.Group>
            <Form.Group controlId="formComment">
            <Form.Label>Комментарий</Form.Label>
            <Form.Control
            as="textarea"
            rows={3}
            name="comment"
            value={formData.comment}
            onChange={handleFormChange}
            />
            </Form.Group>
            <div className="d-flex justify-content-center pt-4">
          {submitted ? (
            <Button className="btn btn-lg btn-warning" onClick={closeModal}>
            Закрыть
            </Button>
            ) : (
            <Button
            className="btn btn-lg btn-warning"
            type="submit"
            onClick={handleFormSubmit}
            disabled={
            formData.name === '' || // Проверяем поля на пустоту
            formData.phone === ''}
            >
            Отправить
            </Button>
            )}
            </div>

            </Form>
            )}
        </Modal.Body>
      </Modal>
    </div>
  );
}
