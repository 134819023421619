export default function uniqueProducts(products) {
  return products.reduce((acc, curr) => {
    const index = acc.findIndex(item => item.article === curr.article);
    if (index !== -1) {
      acc[index].count += curr.count;
      acc[index].totalPrice = acc[index].count * parseFloat(acc[index].price.replace(' руб.', '').replace(' ', ''));
    } else {
      const totalPrice = curr.count * parseFloat(curr.price.replace(' руб.', '').replace(' ', ''));
      acc.push({...curr, totalPrice});
    }
    return acc;
  }, []);
}
