import './Manufacturers.css'
import Preview from "../Preview/Preview"
import React, {useEffect, useState} from "react"
import {productsByManufacturerId} from "../../utils/illumination/api"
import {Link, useLocation} from 'react-router-dom'
import Preloader from '../Preloader/Preloader'
import parserPath from "../../utils/parserPath";
import calcRangeList from "../../utils/calcRangeList";
import PaginationComp from "../Pagination/Pagination";
import {CATEGORY_ID, MANUFACTURERS, VENDOR_COLLECTIONS, VIEW_COLLECTIONS} from "../../constants/constants";

export default function Manufacturers({isLoading, setIsLoading, addToBasket, setIsNavbar}) {
  const [productList, setProductList] = useState([])
  const [brandInfo, setBrandInfo] = useState([])
  const location = useLocation()
  const {categoryId} = parserPath(location.pathname)
  const [collectionListLength, setCollectionListLength] = useState(0)
  const [emptyPage, setEmptyPage] = useState(false)

  useEffect(_ => {
    setIsLoading(true)
    setIsNavbar(false)
    window.scrollTo(0, 0);
    const {categoryId, pageId} = parserPath(location.pathname)
    let {start, end } = calcRangeList(pageId)
    productsByManufacturerId(categoryId, start, end)
      .then((data) => {
        setBrandInfo(data.manufacture)
        setProductList(data.result)
        setCollectionListLength(data.listLength)

        if (data.result.length === 0) {
          setEmptyPage(true)
        }
      })
      .finally(() => setTimeout(() => setIsLoading(false), 500))

  }, [location.pathname])

  function EmptyPageCap() {
    return (
      <div className="empty-page">
        <h3 className="empty-page__title">К сожалению в данный момент товары отсутствуют</h3>
        <Link
          className="empty-page__link"
          to={`/vendors`}
        >
          Вернуться к производителям
        </Link>
      </div>
    )
  }

  return (
    <>
    {
      isLoading ? <Preloader /> :
        <div className="products__category">
          <div className="manufacturers-title">
            {brandInfo.image && <img className="manufacturers-title__logo" src={`https://www.svetlux.ru/images/pics1/${brandInfo.image}`} alt="" />}
            <div className="page__title">{brandInfo.name}</div>
          </div>
        {
          emptyPage ? <EmptyPageCap /> :
            <>
              <Link to={`/${VENDOR_COLLECTIONS}/${CATEGORY_ID}=${categoryId}&pageId=1`} className="btn btn-warning btn-collection">{VIEW_COLLECTIONS}</Link>
              <div className="products__list">
                {productList?.map((el, i) => {
                  return <Preview key={i} id={el.id} cardData={el} addToBasket={addToBasket}/>
                })
                }
              </div>
              <PaginationComp active={parserPath(location.pathname).pageId} listLength={collectionListLength} path={MANUFACTURERS} categoryName={CATEGORY_ID}/>
            </>
        }
        </div>
    }
    </>
  )
}
