import './styles/VendorsPage.css';
import React, { useEffect, useState } from 'react';
import { getManufacturers } from '../../utils/illumination/api';
import { Link } from 'react-router-dom';

export default function VendorsPage() {
  const [manufacturers, setManufacturers] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    getManufacturers()
      .then((data) => {
        setManufacturers(data.manufacturersList);
      });
  }, []);

  const filteredManufacturers = manufacturers.filter((manufacturer) => {
    if (searchValue.length === 0) {
      return true;
    }
    const manufacturerName = manufacturer.name.toLowerCase();
    const searchValueLowerCase = searchValue.toLowerCase();
    return manufacturerName.includes(searchValueLowerCase);
  });

  const groupedManufacturers = filteredManufacturers.reduce((result, manufacturer) => {
    const firstLetter = manufacturer.name.charAt(0).toUpperCase();
    result[firstLetter] = result[firstLetter] || [];
    result[firstLetter].push(manufacturer);
    return result;
  }, {});

  const letters = Object.keys(groupedManufacturers).sort();

  function scrollToLetter(letter) {
    const letterElement = document.getElementById(`letter-${letter}`);
    if (letterElement) {
      letterElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <div className="vendorsPage">
      <div className="products__title page__title">Производители</div>
      <div className="products-alphabet">
        <div className="vendors-navigation">
          <div className="letters-column">
            {letters.map((letter, index) => (
              <div key={index} className="letter" onClick={() => scrollToLetter(letter)}>
                {letter}
              </div>
            ))}
          </div>

          <div className="search-bar">
            <input
              type="text"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder="Поиск по производителям"
            />
          </div>
        </div>
        <div className="manufacturer-list">
          {letters.map((letter) => (
            <div key={letter}>
              <h2 id={`letter-${letter}`}>{letter}</h2>
              <div className="manufacturer-columns">
                {groupedManufacturers[letter].map((manufacturer) => (
                  <Link
                    to={`/manufacturers/categoryId=${manufacturer.id}&pageId=1`}
                    key={manufacturer.name}
                    className="manufacturer-item"
                    state={{ name: 'qwer', image: 's' }}
                  >
                    {manufacturer.name}
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
