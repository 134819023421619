import './CategoryDoors.css'
import {Link} from "react-router-dom";
import {CATEGORY_ID, PAGE_ID, PARENT_CATEGORY_ID} from "../../../constants/constants";
import {checkImage} from "../../../utils/doors/apiDoors";
import {useEffect, useState} from "react";

export default function CategoryDoors({name, id, preview, categoryType, pageId}) {
  // https://brilliant24.ru/files/cat/template_01.png

  const [image, setImage] = useState(preview)


  useEffect(() => {
    if(image){
      checkImage(preview)
        .then(res => {
          console.log(res)
          if(res){
            setImage(preview)
          }else{
            setImage("https://brilliant24.ru/files/cat/template_01.png")
          }
        })
    }

  }, [])


  return (
    <Link to={`${categoryType}=${id}${pageId}`} className="parent-category__link">
      <img src={image} alt="превью" className="parent-category__preview"/>
      <p className="parent-category__title">{name}</p>
    </Link>
  )
}
