export default function calcRangeList(pageId) {
  let start, end
  if(+pageId === 1){
    start = 0
    end = 30
  }else{
    start = (+pageId - 1) * 30
    end = start + 30
  }
  return {
    start, end
  }
}
