export default function parserPath(path) {
  const parts = path.split('/')
  const endPoint = parts[parts.length - 1]
  let categoryId, pageId;
  categoryId = endPoint.split('&')[0]
  pageId = endPoint.split('&')[1]
  categoryId = categoryId.split('=')[1]
  pageId = pageId.split('=')[1]
  return {categoryId, pageId}
}
