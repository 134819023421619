import Pagination from "react-bootstrap/Pagination";
import React from "react";
import './PaginationPages.css'
import {NEXT, PREV} from "../../constants/constants";

export default function PaginationPages({ setPage, active }) {
  return(
    <Pagination size='lg' className='pagination__wrapper'>
      <Pagination.Prev
        onClick={() => setPage(PREV)}
        disabled={active <= 1}
        linkClassName='pagination__item'
      >предыдущая</Pagination.Prev>
      <p className='pagination__num'>{active}</p>
      <Pagination.Next
        linkClassName='pagination__item'
        onClick={() => setPage(NEXT)}
        disabled={false}
      >следующая</Pagination.Next>
    </Pagination>
  )
}
