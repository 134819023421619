import './styles/LampHelper.css'
import Preview from "../Preview/Preview";
import {useEffect, useState} from "react";
import {searchLampsForProduct} from "../../utils/illumination/api";

export default function LampHelper({valuePlinth, addToBasket}) {
  const [lampsList, setLampsList] = useState([])
  useEffect(_=> {
    if(valuePlinth){
      searchLampsForProduct(valuePlinth)
        .then((res) => setLampsList(res))
        .catch((err) => 'Ошибка с лампами: ' + err)
    }
  }, [])

  if(!lampsList.length) return null

  return (
    <div className="wrapper">
      <div className="lamps">
        {
          lampsList.map((el) => <Preview key={el.id} id={el.id} cardData={el} addToBasket={addToBasket}/>)
        }
      </div>
    </div>
  )
}
