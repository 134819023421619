import './Size.css'

export default function Size({setValue, formData}) {
  return (
    <div className="size__wrapper">
      <div className="size__item">
        <p className="size__desc">Длина, см.</p>
        <input
          min="0"
          max="220"
          name="min-size"
          placeholder={0}
          value={formData['length']['min']}
          onChange={option => setValue(option, 'length','min')}
          id="min-size"
          type="number"
          className="price__input price__input_min input"/>

        <input
          min="0"
          max="220"
          name="max-size"
          placeholder={220}
          value={formData['length']['max']}
          onChange={option => setValue(option, 'length','max')}
          id="min-size"
          type="number"
          className="price__input price__input_max input"/>
      </div>

      <div className="size__item">
        <p className="size__desc">Высота, см.</p>
        <input
          min="0"
          max="210"
          name="min-height"
          placeholder={0}
          value={formData['height']['min']}
          onChange={option => setValue(option, 'height','min')}
          id="min-size"
          type="number"
          className="price__input price__input_min input"/>

        <input
          min="0"
          max="210"
          name="max-height"
          placeholder={210}
          value={formData['height']['max']}
          onChange={option => setValue(option, 'height','max')}
          id="min-size"
          type="number"
          className="price__input price__input_max input"/>
      </div>

      <div className="size__item">
        <p className="size__desc">Диаметр, см.</p>
        <input
          min="0"
          max="300"
          name="min-diameter"
          placeholder={0}
          value={formData['diameter']['min']}
          onChange={option => setValue(option, 'diameter','min')}
          id="min-size"
          type="number"
          className="price__input price__input_min input"/>

        <input
          min="0"
          max="300"
          name="max-diameter"
          placeholder={300}
          value={formData['diameter']['max']}
          onChange={option => setValue(option, 'diameter','max')}
          id="min-size"
          type="number"
          className="price__input price__input_max input"/>
      </div>
    </div>
  )
}
