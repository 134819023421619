import React from "react";
import "./Preloader.css";
import logo from "../../UI/icons/logo.svg"
const Preloader = () => {
  return (
    <div className="preloader">
      <div className="preloader__container">
        <img src={logo} alt="Miradec Preloader" className="preloader__image" />
      </div>
    </div>
  );
};

export default Preloader;
