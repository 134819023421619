import './styles/BasketPage.css'
import React, { useEffect, useState } from "react";
import {Trash, Dash, Plus} from "react-bootstrap-icons";
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Col, Row, Form } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import FeedbackModal from '../../components/FeedbackModal/FeedbackModal';
import QuantityStatus from '../../components/QuantityStatus/QuantityStatus';
import {logDOM} from "@testing-library/react";

export default function BasketPage({updateBasketData}) {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  const [articleCounts, setArticleCounts] = useState({});
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const parseJSON = JSON.parse(localStorage.getItem('basket'))

    if (parseJSON) {
      setData(parseJSON);
      setLoading(false);

      // Calculate and update article counts
      const counts = {};
      parseJSON.forEach(item => {
        if (!counts[item.article]) {
          counts[item.article] = item.count;
        } else {
          counts[item.article] += item.count;
        }
      });
      setArticleCounts(counts);
    }
  }, []);

  const handleDeleteItem = (index,  article) => {
    const newData = [...data].filter(el => el.article !== article)
    setData(newData)
    updateBasketData(newData)
  }

  const handleDecrement = (index, article) => {
    const newData = [...data]
    if (newData.filter((el) => el.article === article).length - 1 !== 0) {
      newData.splice(index, 1)
      setData(newData)
      updateBasketData(newData)
      setArticleCounts({ ...articleCounts, [article]: articleCounts[article] - 1 })
    }

  };

  const handleIncrement = (index, article) => {
    const newItem = { ...groupedData[index], count: 1 }; // Создаем новую запись с количеством 1
    const newData = [...data, newItem]; // Добавляем новую запись

    setData(newData);
    updateLocalStorage(newData);
    updateBasketData(newData)
    setArticleCounts({ ...articleCounts, [article]: articleCounts[article] + 1 })
  }

  const handleCountChange = (index, newCount) => {
    const newData = [...data];
    newData[index].count = newCount;
    setData(newData);
    updateLocalStorage(newData);
  };

  const updateLocalStorage = (newData) => {
    localStorage.setItem('basket', JSON.stringify(newData))
  };

  const groupItemsByArticle = (data) => {
    if (!data) {
      return [];
    }

    return data.reduce((acc, curr) => {
      const existingItem = acc.find(item => item.article === curr.article);
      if (!existingItem) {
        return [...acc, { ...curr, count: 1 }];
      } else {
        existingItem.count += 1
        return acc;
      }
    }, []);
  };

  const calculateTotalPrice = () => {
    if (data) {
      const totalPrice = data.reduce((acc, el) => {
        const itemPrice = parseInt(el.price.replace(/\s/g, '')) * el.count;
        return acc + itemPrice;
      }, 0);
      return totalPrice.toLocaleString();
    }
    return 0;
  };

  const handleOfferButton = () => {
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  };


  const groupedData = groupItemsByArticle(data);

  return (
    <>
    <section style={{ backgroundColor: "white" }}>

      <Container className="py-1 h-100">
        <Row className="justify-content-center align-items-start h-100">
          <Col md="10">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h1 className="fw-normal mb-0 text-black">
                Корзина
              </h1>
              <div>
              </div>
            </div>
            <div className="cards">
            {!loading &&
              groupedData.map((el, index) => {
                const sum = parseInt(el.price.replace(/\s/g, '')) * el.count;

                return (
                  <Card key={index} className="rounded-3 mb-4">
                    <Card.Body className="p-4">
                      <Row className="justify-content-between align-items-center">
                        <Col md="2" lg="2" xl="2">
                        <Card.Img className="rounded-3" fluid="true" src={el.image} alt={el.article} />

                        </Col>
                        <Col md="3" lg="3" xl="3">
                          <p className="lead fw-normal mb-2">{el.name}</p>
                          <p>
                            <span className="text-muted">{el.article}</span>
                          </p>
                          <QuantityStatus quantity={el.quantity}/>
                        </Col>
                        <Col md="3" lg="3" xl="2" className="d-flex align-items-center justify-content-around">
                        <Button variant="link" className="px-0" onClick={() => handleDecrement(index, el.article)}>
                          <Dash size={25} color='gray' className="menu__item__image"/>
                        </Button>

                          <Form.Control
                            min={1}
                            max={10}
                            type="number"
                            value={articleCounts[el.article]}
                            onChange={(e) => handleCountChange(index, parseInt(e.target.value))}
                            size="sm"
                          />

                          <Button variant="link" className="px-0" onClick={() => handleIncrement(index, el.article)}>
                            <Plus size={25} color='gray' className="menu__item__image"/>
                          </Button>
                        </Col>

                        <Col md="3" lg="2" xl="2" className="offset-lg-1">
                          <h5 className="mb-0">
                            {sum.toLocaleString() + " руб."}
                          </h5>
                        </Col>

                        <Col md="1" lg="1" xl="1" className="text-end">
                          <a href="#!" className="text-danger" onClick={() => handleDeleteItem(index, el.article)}>
                            <Trash size={25} color='gray' className="menu__item__image"/>
                          </a>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                );
              })}
            </div>
          </Col>
        </Row>
      </Container>

      <div className="sticky-bottom bg-white p-3">
      <div className="container d-flex justify-content-between align-items-center">
        <h3>Товары ({data?.length}): <b>{calculateTotalPrice() + ' руб.'}</b> </h3>
        <button className="btn btn-warning" onClick={handleOfferButton}>
          Перейти к оформлению
        </button>
      </div>
    </div>


    </section>
    <FeedbackModal showModal={showModal} closeModal={closeModal} updateBasketData={updateBasketData} />
    </>

  );
  }
