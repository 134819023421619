import './AccordionCategory.css'
import {Link, useLocation} from "react-router-dom";
import arrow from '../../../../UI/icons/down.svg'
import {useEffect, useState} from "react";
import {CATEGORY_ID, DOORS, PAGE_ID, PARENT_CATEGORY_ID, SUB_CATEGORY_ID} from "../../../../constants/constants";
import parserPath from "../../../../utils/parserPath";
import calcRangeList from "../../../../utils/calcRangeList";
import {getCatalogById, getTailProductsDoorsParentLevel} from "../../../../utils/doors/apiDoors";

export default function AccordionCategory({data, setIsLoading}) {
  const location = useLocation()
  const [isCollapse, setIsCollapse] = useState(false)
  if(!data){
    return null
  }
  const {name, subCategory, id, parentCategoryId} = data

  function collapseCategory(){
    setIsCollapse(!isCollapse)
  }

  return (
    <div className="custom-accordion-category">
      <div className="custom-accordion-category__header">
        <Link to={`/${DOORS}/${PARENT_CATEGORY_ID}=${parentCategoryId}/${CATEGORY_ID}=${id}&${PAGE_ID}=1`} className="custom-accordion-category__link">{name}</Link>
        {
          subCategory.length > 0 &&
          <button className="custom-accordion-category__collapse" onClick={collapseCategory}>
            <img src={arrow} alt=""
                 className={`custom-accordion-category__collapse-image ${isCollapse ? 'custom-accordion-category__collapse-image_active' : ''}`}/>
          </button>
        }
      </div>
      {
        <ul className={`custom-accordion-category__body ${isCollapse ? 'custom-accordion-category__body_active' : ''}`}>
          {
            subCategory?.map(el => {
              return (
                <li key={el.id} className="custom-accordion-category__item">
                  <Link to={`/${DOORS}/${PARENT_CATEGORY_ID}=${parentCategoryId}/${CATEGORY_ID}=${id}/${SUB_CATEGORY_ID}=${el.id}&${PAGE_ID}=1`} className="custom-accordion-category__link">{el.name}</Link>
                </li>
              )
            })
          }
        </ul>
      }
    </div>
  )
}
