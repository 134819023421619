import './CategoryItem.css'
import {Link} from "react-router-dom";
import {converterImageName} from "../../utils/converterImageName";
import {CATEGORIES} from "../../constants/constants";

export default function CategoryItem({link, name, isFooter = false, imageType, hideModal}) {
  let image = converterImageName(imageType)
  return (
    <Link to={`${CATEGORIES}/${link}`} className="category__link" onClick={() => hideModal()}>
      {!isFooter && <img src={image} alt="menu item" className="category__image"/>}
      <p className={`${!isFooter ? 'category__name' : 'category__name category__name_type_footer'} `}>{name}</p>
    </Link>
  )
}
