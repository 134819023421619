export const categories = [
  {
    id: "0",
    label: "Все типы",
    value: "all_types",
  },
  {
    id: "1",
    label: "Люстры",
    value: "lyustri",
  },
  {
    id: "2",
    label: "Светильники",
    value: "svetilniki",
  },
  {
    id: "3",
    label: "Бра",
    value: "bra",
  },
  {
    id: "5",
    label: "Торшеры",
    value: "torsheri",
  },
  {
    id: "11",
    label: "Настольные лампы",
    value: "nastolnie_lampi",
  },
  {
    id: "6",
    label: "Трековые и струнные системы",
    value: "trekovie_i_strunnie_sistemi",
  },
  {
    id: "4",
    label: "Споты",
    value: "spoti",
  },
  {
    id: "13",
    label: "Светодиодные ленты",
    value: "svetodiodnie_lenti",
  },
  {
    id: "12",
    label: "Электротовары",
    value: "elektrotovari",
  },
  {
    id: "7",
    label: "Уличные светильники",
    value: "ulichnie_svetilniki",
  },
  {
    id: "8",
    label: "Гирлянды",
    value: "girlyandi",
  },
  {
    id: "9",
    label: "Лампы",
    value: "lampi",
  },
  {
    id: "10",
    label: "Комплектующие",
    value: "komplektuyushchie",
  },
]
