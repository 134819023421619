import './styles/Product.css'
import priceCalculation from "../../utils/priceCalculation"
import {useLocation} from "react-router-dom"
import GalleryCard from "../GalleryCard/GalleryCard";
import SpectTabs from '../Specs/SpecsTabs/SpecsTabs';
import {useEffect, useState} from 'react';
import {getProductById, getProductsByCollection} from "../../utils/illumination/api";
import FromThisSeries from "../FromThisSeries/FromThisSeries";
import QuantityStatus from '../QuantityStatus/QuantityStatus';
import Preloader from '../Preloader/Preloader'

export default function Product({isLoading, setIsLoading, addToBasket}) {
  let {pathname} = useLocation()
  const pathnameList = pathname.split('/')
  const id = pathnameList[pathnameList.length - 1]
  const [seriesProduct, setSeriesProduct] = useState([])
  const [basketCount, setBasketCount] = useState(1)
  const [cardData, setCardData] = useState(null)
  const [price, setPrice] = useState('')
  const [discount, setDiscount] = useState('')

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname])

  useEffect(_ => {
    setIsLoading(true)
    getProductById({id: id.toString()})
      .then(product => {
        setCardData(product)
        const cardData = product
        getProductsByCollection({collectionId: cardData.categoriesId})
          .then(
            productsFromCollection => setSeriesProduct(productsFromCollection.filter((product) => product.id !== id)))
        setPrice(priceCalculation(cardData.regularPrice))
        setDiscount(priceCalculation(cardData.specialPrice))
      })
      .catch(console.error)
      .finally(() => setTimeout(() => setIsLoading(false), 500))
  }, [id])
  // const addToBasket = (e, name, article, price, image, basketCount, quantity) => {
  const addToBasketHandler = (event) => {
    const data = {
      type: "illumination",
      name: cardData?.name,
      article: cardData?.model,
      price,
      image: cardData?.image,
      quantity: cardData?.quantity,
      count: basketCount,
    }
    // addToBasket(event, cardData?.name, cardData?.model, price, cardData?.image, basketCount, cardData.quantity, cardData.id)
    addToBasket(event, data, basketCount)
    setBasketCount(1)
  }

  if(cardData === null) return

  return (
    <>
    {
      isLoading ?
        <Preloader/>
        :
        <div className="product">
        <div className="product__display">
          <h3 className="product__title">{cardData.name}</h3>
          <h2 className="product__code">{cardData.model}</h2>
          <GalleryCard
            id={id}
            setIsLoading={setIsLoading}/>
        </div>

          <div className="price">
            <div className="product-info">
              <p className="product-info__name">Артикул:</p>
              <p className="product-info__name_type_val">{cardData.model}</p>
            </div>
            <div className="product-info">
              <p className="product-info__name">Производитель:</p>
              <p className="product-info__name_type_val">{`${cardData.manufacturerName} ${cardData.manufacturerCountry}`}</p>
            </div>
            <div className="calculator">
              <QuantityStatus quantity={cardData.quantity}/>
              <div className="calculator__price">
                <span className={cardData.specialPrice !== null ? 'calculator__regular-price' : 'calculator__special-price'}>{price}</span>
                <span className="calculator__special-price">{cardData.specialPrice ? discount : null}</span>
              </div>
              <form className="calculator__form" onSubmit={(event) => addToBasketHandler(event)}>
                <input
                  className="calculator__count"
                  type="number"
                  placeholder="1"
                  min="1"
                  value={basketCount}
                  onChange={(event) => setBasketCount(event.target.value)}
                />
                <button type="submit" className="btn btn-warning calculator__submit" style={{borderRadius: '12px'}}>Добавить
                  в корзину
                </button>
              </form>
            </div>
          </div>

        <SpectTabs
          addToBasket={addToBasket}
          setIsLoading={setIsLoading}
          information={cardData.information}
          model={cardData.model}
          name={cardData.name}
          />
        {
          cardData.description &&
          <div className="description">
            <h5>Описание {cardData.model}</h5>
            <p dangerouslySetInnerHTML={{__html: cardData.description}}/>
          </div>
        }

        <div className="series__products">
          <h5 className='series__products-title'>Товары из этой серии:</h5>
          <FromThisSeries addToBasket={addToBasket} data={seriesProduct}/>
        </div>
      </div>
    }
    </>
  )
}



