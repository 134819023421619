import React, { useState } from 'react';
import './styles/FeedbackForm.css'

export default function FeedbackForm() {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [question, setQuestion] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    setName('');
    setPhone('');
    setQuestion('');
  };

  return (
    <>
      <div className="feedback__title">Обратная связь</div>
      <form className="feedback__form" onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Как Вас зовут"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="text"
          placeholder="+7 (495) 123-4567"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <textarea
          placeholder="Ваш вопрос"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
        ></textarea>
        <button type="submit" className='btn btn-warning calculator__submit feedback-form__button'>Отправить</button>
      </form>
    </>

  );
};
