import './FilterList.css'
import React, {useEffect, useState} from "react";
import Preloader from "../../Preloader/Preloader";
import NoResults from "../../NoResults/NoResults";
import Preview from "../../Preview/Preview";
import {useLocation, useNavigate} from "react-router-dom";
import {getForcards, getForCategories, getForCollections, getForVendors} from "../../../utils/illumination/apiFilter";
import PreviewCollection from "../../PreviewCollection/PreviewCollection";
import PaginationPages from "../../PaginationPages/PaginationPages";

export default function FilterList(
  {
    formDataForFilter,
    upt,
    setCounter,
    counter,
    setTypeOfFilter,
    typeOfFilter
  }) {
  const navigate = useNavigate()
  let {pathname} = useLocation()
  const path = pathname.split('/')[2]

  const [isLoaded, setIsLoaded] = useState(false)
  const [filterResultList, setFilterResultList] = useState([])
  const [isCollection, setIsCollection] = useState(false)
  const [actionFromButton, setActionFromButton] = useState(false)
  const [end, setEnd] = useState(30)
  const [start, setStart] = useState(0)
  const [active, setActive] = useState(1)
  const [productsListLength, setProductsListLength] = useState(0)

  function goBack() {
    navigate("/")
  }


  function setPage(paginationType) {
    setActionFromButton(true)
    window.scrollTo(0, 0);
    if(paginationType === 'next'){
      setActive(active + 1)
      setEnd(end + 30)
      setStart(start + 30)
    }else{
      setActive(active - 1)
      setEnd(end - 30)
      setStart(start - 30)
    }
  }

  useEffect(_ => {
    if(actionFromButton){
      window.scrollTo(0, 0);
      setActionFromButton(false)
    }
    if(formDataForFilter.noRes){
      setFilterResultList({noRes: true})

    }else {
      setIsLoaded(true)
      switch (path) {
        case 'categories':
          getForCategories(formDataForFilter)
            .then(res => {
            if (res.nodata === 'empty query') {
            } else if (res.nodata?.includes('nodata')) {
              localStorage.setItem('filteredData', JSON.stringify({noRes: true}))
              setFilterResultList(res)
              setProductsListLength(res.length)
            } else {
              localStorage.setItem('filteredData', JSON.stringify(formDataForFilter))
              setFilterResultList(res)
              setProductsListLength(res.length)
            }
          })
            .finally(_ => {
              setIsLoaded(false)
            })
          break
        case 'collections':
          getForCollections(formDataForFilter)
            .then(res => {
              if (res.nodata === 'empty query') {
              } else if (res.nodata?.includes('nodata')) {
                localStorage.setItem('filteredData', JSON.stringify({noRes: true}))
                setFilterResultList(res)
                setProductsListLength(res.length)
              } else {
                setIsCollection(true)
                localStorage.setItem('filteredData', JSON.stringify(formDataForFilter))
                setFilterResultList(res)
                setProductsListLength(res.length)
              }
            })
            .finally(_ => {
              setIsLoaded(false)
            })
          break
        case 'vendors':
          getForVendors(formDataForFilter)
          break
        case 'cards':
          getForcards(formDataForFilter)
          break
        case 'filterlist':
          navigate(`/filterlist/${typeOfFilter}`)
          setFilterResultList(formDataForFilter)
          break
        default:
          console.log('no matches')
      }
      setCounter(counter + 1)
    }}, [formDataForFilter, upt])

  if (filterResultList.nodata || filterResultList["noRes"]){
    return (
      <NoResults title="Поиск не дал результатов" goBack={goBack}/>
    )
  }

  return (
    <>
      {
        isLoaded ? <Preloader/> :
          <div className="products__category">
            <div className="products__list">
              {
                !isCollection && filterResultList?.map((el, i) => {
                  if (i > end || i < start) {
                    return null
                  }
                  return (<Preview key={el.id} id={el.id} cardData={el}/>)
                }) ||
                isCollection && filterResultList?.map((el, i) => {
                  if (i > end || i < start) {
                    return null
                  }
                  return (<PreviewCollection key={i} rout={9999} data={el}/>)
                })
              }

            </div>
            <div className="pagination__wrapper">
              <PaginationPages setPage={setPage} active={active} listLength={productsListLength}/>
            </div>
          </div>



      }

    </>
  )
}
