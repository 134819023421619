export const typeOfLamp =
  {
    id: 0,
    name: 'Все',
    parent: '0',
    label: 'Все',
    value: 'all_types'
  }


