import {Accordion} from "react-bootstrap";
import './AccordionItem.css'

export default function AccordionItem({title, child, eventKey}) {
  return (
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>
        {title}
      </Accordion.Header>
      <Accordion.Body>
        {child}
      </Accordion.Body>
    </Accordion.Item>
  )
}
