import checkResponse from "../checkresponse";
// const baseUrl = 'https://miradec.ru/api/'
const baseUrl = 'https://miradec.ru/doors/api/'
// const baseUrl = 'https://miradec.ru/test/api/'
// const baseUrl = 'http://localhost:3002/test/api/'
// const baseUrl = 'http://localhost:3003/doors/api/'

export function getCatalog() {
  return fetch(`${baseUrl}catalogs/get-catalog`)
    .then(checkResponse);
}

// Функция для проверки изображения
export function checkImage(url) {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(true);  // Изображение успешно загружено
    img.onerror = () => resolve(false); // Ошибка при загрузке изображения
    img.src = url;
  });
}

export function getCatalogById(categoryId) {
  return fetch(`${baseUrl}catalogs/get-catalog-by-id?id=${categoryId}`)
    .then(checkResponse);
}

export function getTailProductsDoorsParentLevel({start, end, categoryId}) {
  const data = {
    start,
    end,
    categoryId
  };
  return fetch(`${baseUrl}products/get-products-parent-level`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(checkResponse);
}

export function getTailProductsDoorsCategoryLevel({start, end, categoryId, parentCategoryId}) {
  const data = {
    start,
    end,
    categoryId,
    parentCategoryId
  };
  return fetch(`${baseUrl}products/get-products-category-level`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(checkResponse);
}

export function getTailProductsDoorsSubCategoryLevel({start, end, categoryId}) {
  const data = {
    start,
    end,
    categoryId
  };
  return fetch(`${baseUrl}products/get-products-sub-category-level`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(checkResponse);
}

export function getProductDoorById(productId) {
  const data = {
    id: +productId
  };
  return fetch(`${baseUrl}products/get-product-by-id`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(checkResponse);
}

export function getProductsInComplectDoorByIds(idList) {
  const data = {
    idList
  };
  return fetch(`${baseUrl}products/get-products-in-complect-by-ids`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(checkResponse);
}


