import bra from '../UI/icons/bra.svg'
import elektrotovary from '../UI/icons/elektrotovary.svg'
import girlyandy from '../UI/icons/girlyandy.svg'
import komplektuushie from '../UI/icons/komplektuushie.svg'
import lampy from '../UI/icons/lampy.svg'
import lustry from '../UI/icons/lustry.svg'
import nastolnyeLampy from '../UI/icons/nastolnye-lampy.svg'
import spoty from '../UI/icons/spoty.svg'
import svetilniki from '../UI/icons/svetilniki.svg'
import torshery from '../UI/icons/torshery.svg'
import trekSyst from '../UI/icons/trek-syst.svg'
import ulichnyeSvetilniki from '../UI/icons/ulichnye-svetilniki.svg'
import diodLines from '../UI/icons/diodLines.svg'

export function converterImageName(name){
  let image
  switch (name) {
    case 'bra':
      image = bra
      break;
    case 'elektrotovari':
      image = elektrotovary
      break;
    case 'girlyandi':
      image = girlyandy
      break;
    case 'komplektuyushchie':
      image = komplektuushie
      break;
    case 'lampi':
      image = lampy
      break;
    case 'lyustri':
      image = lustry
      break;
    case 'nastolnie_lampi':
      image = nastolnyeLampy
      break;
    case 'spoti':
      image = spoty
      break;
    case 'svetilniki':
      image = svetilniki
      break;
    case 'torsheri':
      image = torshery
      break;
    case 'trekovie_i_strunnie_sistemi':
      image = trekSyst
      break;
    case 'ulichnie_svetilniki':
      image = ulichnyeSvetilniki
      break;
    case 'svetodiodnie_lenti':
      image = diodLines
      break;
    default:
      image = diodLines
      break;
  }

  return image
}
