export const filterCheckboxesOfAccordion = [
  {
    title: 'Стиль',
    name: 'style',
  },
  {
    title: 'Место применения',
    name: 'placeOfApplication',
  },
  {
    title: 'Напряжение',
    name: 'voltage',
  },
  {
    title: 'Количество ламп',
    name: 'lampCoins',
  },
  {
    title: 'Тип цоколя ламп',
    name: 'lampBaseType',
  },
  {
    title: 'Цветовая температура',
    name: 'colorTemperature',
  },
  {
    title: 'Материалы',
    name: 'materials',
  },
  {
    title: 'Степень защиты',
    name: 'levelOfDefend',
  },
  {
    title: 'Цвет арматуры',
    name: 'armatureColor',
  },
  {
    title: 'Цвет плафона',
    name: 'lampshadeColor',
  },
  {
    title: 'Выключатель',
    name: 'switchLight',
  },
  {
    title: 'Способ крепления',
    name: 'variantPin',
  },
  {
    title: 'Производитель',
    name: 'manufacturer',
  },
  {
    title: 'Страна',
    name: 'land',
  },
]

export const styleList = [
  'Арт-деко',
  'Восточный',
  'Замковый',
  'Кантри',
  'Классика',
  'Лофт',
  'Модерн',
  'Морской',
  'Прованс',
  'Ретро',
  'Современный',
  'Тиффани',
  'Флористика',
  'Хай-тек',
]
export const placeOfApplication = [
  'Балкон',
  'Веранда',
  'Гостиная',
  'Детская',
  'Для ванной',
  'Кабинет',
  'Кухня',
  'Магазин',
  'Офис',
  'Прихожая',
  'Спальня',
  'Улица',
]
export const voltage = [
  '12V',
  '220V',
  '24V',
]
export const lampCoins = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20 и более',
]
export const lampBaseType = [
  'E14',
  'E27',
  'G4',
  'G5.3',
  'G53',
  'G9',
  'GU10',
  'GU4',
  'GU5.3',
  'GX5.3',
  'GX53',
  'LED',
  'R7s',
  'T5',
  'T8',
]
export const colorTemperature = [
  'Теплый свет (менее 3500К)',
  'Нейтральный (3500К-4500К)',
  'Холодный свет (более 4500К)',
  'RGB',
]
export const materials = [
  'ПВХ',
  'акрил',
  'гипс',
  'дерево',
  'керамика',
  'кожа',
  'металл',
  'пластик',
  'полимер',
  'ротанг',
  'силикон',
  'стекло',
  'текстиль',
  'ткань',
  'фарфор',
  'хрусталь'
]
export const levelOfDefend = [
  'IP44',
  'IP45',
  'IP54',
  'IP55',
  'IP64',
  'IP65',
  'IP66',
  'IP67',
  'IP68',
]
export const armatureColor = [
  'бежевый',
  'белый',
  'бронзовый',
  'венге',
  'голубой',
  'желтый',
  'зеленый',
  'золотой',
  'коричневый',
  'красный',
  'латунь',
  'медный',
  'никель',
  'оранжевый',
  'прозрачный',
  'разноцветный',
  'розовый',
  'серебряный',
  'серый',
  'синий',
  'слоновая кость',
  'фиолетовый',
  'хром',
  'черный',
]
export const lampshadeColor = [
  'бежевый',
  'белый',
  'бронзовый',
  'венге',
  'голубой',
  'желтый',
  'зеленый',
  'золотой',
  'коричневый',
  'красный',
  'кремовый',
  'латунь',
  'медный',
  'никель',
  'оранжевый',
  'прозрачный',
  'разноцветный',
  'розовый',
  'серебряный',
  'серый',
  'синий',
  'слоновая кость',
  'фиолетовый',
  'хром',
  'черный',
]
export const switchLight = [
  'выключатель-шнурок',
  'датчик движения',
  'диммер',
  'на корпусе',
  'на шнуре',
  'пульт ДУ',
  'сенсорный',
]
export const variantPin = [
  'на крюк',
  'на планку',
  'на прищепке',
  'на струбцине',
]
export const manufacturer = [
  'APL LED',
  'Adilux',
  'Alfa',
  'Ambiente',
  'Ambrella Light',
  'Arlight',
  'ArteLamp',
  'Arti Lampadari',
  'Blitz',
  'Bogates',
  'Bohemia Art Classic',
  'Bohemia Ivele',
  'Brizzi',
  'Chiaro',
  'Citilux',
  'Crystallux',
  'De Markt',
  'Denkirs',
  'DesignLed',
  'Dio Darte',
  'Divinare',
  'Donel',
  'Eglo',
  'Elektrostandard',
  'Eletto',
  'Eurosvet',
  'Evoled',
  'Evoluce',
  'F-Promo',
  'Favourite',
  'Freya',
  'Fumagalli',
  'Gauss',
  'Globo',
  'ILamp',
  'ILedex',
  'Ideallux',
  'Kemar',
  'Kink Light',
  'Kutek',
  'LTC',
  'Lamp4You',
  'LampGustaf',
  'LightStar',
  'Loft IT',
  'Lucia Tucci',
  'Lucide',
  'Luminex',
  'Lumion',
  'Lumker',
  'Lussole',
  'MW-Light',
  'Mantra',
  'Markslojd',
  'Maytoni',
  'NewPort',
  'Novotech',
  'Odeon Light',
  'Paulmann',
  'Reccagni Angelo',
  'ST Luce',
  'SWG',
  'Sonex',
  'Stilfort',
  'TK Lighting',
  'TopDecor',
  'Toplight',
  'Velante',
  'Vele Luce',
  'Voltega',
  'Werkel',
  'Wertmark',
]
export const land = [
  'Австрия',
  'Бельгия',
  'Венгрия',
  'Германия',
  'Дания',
  'Испания',
  'Италия',
  'Китай',
  'Польша',
  'Россия',
  'США',
  'Чехия',
  'Швеция',
]
