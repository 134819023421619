import test from '../../UI/icons/test.svg'
import Preloader from "../Preloader/Preloader";


export default function Test() {
  return (
    <div>
      {/*<img src={test} alt="" className="test-img"/>*/}
      <Preloader/>
    </div>
  )
}
