export const pages = [
  {
    id: "1",
    name: "Световое оборудование",
    link: "illumination",
    image: "https://www.svetlux.ru/images/pics1/products/1/5/2/5/1525b56a1c362d56078d0dacb2188695.jpg"
  },
  {
    id: "2",
    name: "Двери",
    link: "doors",
    image: "https://shop.za-door.ru/upload/iblock/6f7/6f7903dc07542420fb03aec419d0ac9e.png"
  },
]
