export const cardData = [
  {
    "id": "73759",
    "name": "Уличный настенный светильник Globe",
    "model": "G25.141.000.WYF1R",
    "quantity": "10",
    "regularPrice": "15636.0000",
    "image": "https:\/\/www.svetlux.ru\/images\/pics1\/products\/f\/d\/0\/d\/fd0d80145c76d98ce483012947cd6c17.jpg",
    "manufacturerName": "Fumagalli",
    "manufacturerCountry": "Италия",
    "categoriesName": "серия GLOBE 250 WHITE 2 FU",
    "categoriesId": "29541",
    "description": "Уличный настенный светильник Globe G25.141.000.WYE27 от итальянского бренда Fumagalli. Данный светильник серии GLOBE 250 WHITE 2 FU отличает неповторимый дизайн, который может стать важным акцентом уличного освещения.<br \/>\nРазмеры: Высота - 37 см; Ширина - 60 см.<br \/>\nБренд Fumagalli оснастил данную модель лампами с цоколем E27 и максимальной мощностью 60 Ватт. Подключив светильник, Вы получите хорошее уличное освещение.<br \/>\nЦвет арматуры уличного настенного светильника Globe G25.141.000.WYE27 - белый.",
    "images": [
      "https:\/\/www.svetlux.ru\/images\/pics1\/products\/b\/f\/6\/7\/bf677424e580c708d015a5326e0acdd8.jpg",
      "https:\/\/www.svetlux.ru\/images\/pics1\/products\/b\/3\/6\/f\/b36f64432fff84c506425c9a7d2c1bc5.jpg"],
    "information": [
      {
        "name": "Комплектация лампами",
        "value": ["да"]
      },
      {
        "name": "Общая мощность, W",
        "value": ["120"]
      }, {
        "name": "Цвет плафона",
        "value": ["белый"]
      }, {
        "name": "Максимальная мощность ламп, W",
        "value": ["60"]
      }, {"name": "Тип цоколя ламп", "value": ["E27"]}, {
        "name": "Количество ламп",
        "value": ["2"]
      }, {"name": "Материалы", "value": ["полимер", "PMMA"]}, {
        "name": "Общее количество ламп",
        "value": ["2"]
      }, {"name": "Ширина, см", "value": ["60"]}, {"name": "Высота, см", "value": ["37"]}, {
        "name": "Цвет арматуры",
        "value": ["белый"]
      }, {"name": "Вес, г", "value": ["3350"]}, {"name": "Степень защиты", "value": ["IP55"]}, {
        "name": "Стиль",
        "value": ["Современный"]
      }, {"name": "Производитель", "value": ["Fumagalli"]}, {
        "name": "Дополнительные характеристики",
        "value": ["корпус изготовлен из современного композитного полимера RESIN, прочного, окрашенного в массе, не ржавеющего и не выгорающего на солнце; в комплекте идут крепежи и закладные элементы; рассеиватель выполнен из антивандального, не мутнеющего и не горючего PMMA; срок службы светильника - не менее 10 лет; температура использования от +60 до -90; столбы выше 150 см имеют в основе мощную стальную трубу с двойной оцинковкой и полимерным наполнителем; способ крепления - монтажная пластина"]
      }, {"name": "Плафоны", "value": ["пластик (PMMA)"]}, {
        "name": "Место применения",
        "value": ["Улица", "Веранда"]
      }, {"name": "Вес брутто", "value": ["3.35"]}
    ]
  },
  {
    "id": "73759",
    "name": "Уличный настенный светильник Globe",
    "model": "Другой артикул :)",
    "quantity": "10",
    "regularPrice": "15636.0000",
    "image": "https:\/\/www.svetlux.ru\/images\/pics1\/products\/f\/d\/0\/d\/fd0d80145c76d98ce483012947cd6c17.jpg",
    "manufacturerName": "Fumagalli",
    "manufacturerCountry": "Италия",
    "categoriesName": "серия GLOBE 250 WHITE 2 FU",
    "categoriesId": "29541",
    "description": "Уличный настенный светильник Globe G25.141.000.WYE27 от итальянского бренда Fumagalli. Данный светильник серии GLOBE 250 WHITE 2 FU отличает неповторимый дизайн, который может стать важным акцентом уличного освещения.<br \/>\nРазмеры: Высота - 37 см; Ширина - 60 см.<br \/>\nБренд Fumagalli оснастил данную модель лампами с цоколем E27 и максимальной мощностью 60 Ватт. Подключив светильник, Вы получите хорошее уличное освещение.<br \/>\nЦвет арматуры уличного настенного светильника Globe G25.141.000.WYE27 - белый.",
    "images": [
      "https:\/\/www.svetlux.ru\/images\/pics1\/products\/b\/f\/6\/7\/bf677424e580c708d015a5326e0acdd8.jpg",
      "https:\/\/www.svetlux.ru\/images\/pics1\/products\/b\/3\/6\/f\/b36f64432fff84c506425c9a7d2c1bc5.jpg"],
    "information": [
      {
        "name": "Комплектация лампами",
        "value": ["да"]
      },
      {
        "name": "Общая мощность, W",
        "value": ["120"]
      }, {
        "name": "Цвет плафона",
        "value": ["белый"]
      }, {
        "name": "Максимальная мощность ламп, W",
        "value": ["60"]
      }, {"name": "Тип цоколя ламп", "value": ["E27"]}, {
        "name": "Количество ламп",
        "value": ["2"]
      }, {"name": "Материалы", "value": ["полимер", "PMMA"]}, {
        "name": "Общее количество ламп",
        "value": ["2"]
      }, {"name": "Ширина, см", "value": ["60"]}, {"name": "Высота, см", "value": ["37"]}, {
        "name": "Цвет арматуры",
        "value": ["белый"]
      }, {"name": "Вес, г", "value": ["3350"]}, {"name": "Степень защиты", "value": ["IP55"]}, {
        "name": "Стиль",
        "value": ["Современный"]
      }, {"name": "Производитель", "value": ["Fumagalli"]}, {
        "name": "Дополнительные характеристики",
        "value": ["корпус изготовлен из современного композитного полимера RESIN, прочного, окрашенного в массе, не ржавеющего и не выгорающего на солнце; в комплекте идут крепежи и закладные элементы; рассеиватель выполнен из антивандального, не мутнеющего и не горючего PMMA; срок службы светильника - не менее 10 лет; температура использования от +60 до -90; столбы выше 150 см имеют в основе мощную стальную трубу с двойной оцинковкой и полимерным наполнителем; способ крепления - монтажная пластина"]
      }, {"name": "Плафоны", "value": ["пластик (PMMA)"]}, {
        "name": "Место применения",
        "value": ["Улица", "Веранда"]
      }, {"name": "Вес брутто", "value": ["3.35"]}
    ]
  },
  {
    "id": "73759",
    "name": "Уличный настенный светильник Globe",
    "model": "Третий артикул",
    "quantity": "10",
    "regularPrice": "15636.0000",
    "image": "https:\/\/www.svetlux.ru\/images\/pics1\/products\/f\/d\/0\/d\/fd0d80145c76d98ce483012947cd6c17.jpg",
    "manufacturerName": "Fumagalli",
    "manufacturerCountry": "Италия",
    "categoriesName": "серия GLOBE 250 WHITE 2 FU",
    "categoriesId": "29541",
    "description": "Уличный настенный светильник Globe G25.141.000.WYE27 от итальянского бренда Fumagalli. Данный светильник серии GLOBE 250 WHITE 2 FU отличает неповторимый дизайн, который может стать важным акцентом уличного освещения.<br \/>\nРазмеры: Высота - 37 см; Ширина - 60 см.<br \/>\nБренд Fumagalli оснастил данную модель лампами с цоколем E27 и максимальной мощностью 60 Ватт. Подключив светильник, Вы получите хорошее уличное освещение.<br \/>\nЦвет арматуры уличного настенного светильника Globe G25.141.000.WYE27 - белый.",
    "images": [
      "https:\/\/www.svetlux.ru\/images\/pics1\/products\/b\/f\/6\/7\/bf677424e580c708d015a5326e0acdd8.jpg",
      "https:\/\/www.svetlux.ru\/images\/pics1\/products\/b\/3\/6\/f\/b36f64432fff84c506425c9a7d2c1bc5.jpg"],
    "information": [
      {
        "name": "Комплектация лампами",
        "value": ["да"]
      },
      {
        "name": "Общая мощность, W",
        "value": ["120"]
      }, {
        "name": "Цвет плафона",
        "value": ["белый"]
      }, {
        "name": "Максимальная мощность ламп, W",
        "value": ["60"]
      }, {"name": "Тип цоколя ламп", "value": ["E27"]}, {
        "name": "Количество ламп",
        "value": ["2"]
      }, {"name": "Материалы", "value": ["полимер", "PMMA"]}, {
        "name": "Общее количество ламп",
        "value": ["2"]
      }, {"name": "Ширина, см", "value": ["60"]}, {"name": "Высота, см", "value": ["37"]}, {
        "name": "Цвет арматуры",
        "value": ["белый"]
      }, {"name": "Вес, г", "value": ["3350"]}, {"name": "Степень защиты", "value": ["IP55"]}, {
        "name": "Стиль",
        "value": ["Современный"]
      }, {"name": "Производитель", "value": ["Fumagalli"]}, {
        "name": "Дополнительные характеристики",
        "value": ["корпус изготовлен из современного композитного полимера RESIN, прочного, окрашенного в массе, не ржавеющего и не выгорающего на солнце; в комплекте идут крепежи и закладные элементы; рассеиватель выполнен из антивандального, не мутнеющего и не горючего PMMA; срок службы светильника - не менее 10 лет; температура использования от +60 до -90; столбы выше 150 см имеют в основе мощную стальную трубу с двойной оцинковкой и полимерным наполнителем; способ крепления - монтажная пластина"]
      }, {"name": "Плафоны", "value": ["пластик (PMMA)"]}, {
        "name": "Место применения",
        "value": ["Улица", "Веранда"]
      }, {"name": "Вес брутто", "value": ["3.35"]}
    ]
  },
  {
    "id": "73759",
    "name": "Уличный настенный светильник Globe",
    "model": "Четвертый артикул",
    "quantity": "10",
    "regularPrice": "15636.0000",
    "image": "https:\/\/www.svetlux.ru\/images\/pics1\/products\/f\/d\/0\/d\/fd0d80145c76d98ce483012947cd6c17.jpg",
    "manufacturerName": "Fumagalli",
    "manufacturerCountry": "Италия",
    "categoriesName": "серия GLOBE 250 WHITE 2 FU",
    "categoriesId": "29541",
    "description": "Уличный настенный светильник Globe G25.141.000.WYE27 от итальянского бренда Fumagalli. Данный светильник серии GLOBE 250 WHITE 2 FU отличает неповторимый дизайн, который может стать важным акцентом уличного освещения.<br \/>\nРазмеры: Высота - 37 см; Ширина - 60 см.<br \/>\nБренд Fumagalli оснастил данную модель лампами с цоколем E27 и максимальной мощностью 60 Ватт. Подключив светильник, Вы получите хорошее уличное освещение.<br \/>\nЦвет арматуры уличного настенного светильника Globe G25.141.000.WYE27 - белый.",
    "images": [
      "https:\/\/www.svetlux.ru\/images\/pics1\/products\/b\/f\/6\/7\/bf677424e580c708d015a5326e0acdd8.jpg",
      "https:\/\/www.svetlux.ru\/images\/pics1\/products\/b\/3\/6\/f\/b36f64432fff84c506425c9a7d2c1bc5.jpg"],
    "information": [
      {
        "name": "Комплектация лампами",
        "value": ["да"]
      },
      {
        "name": "Общая мощность, W",
        "value": ["120"]
      }, {
        "name": "Цвет плафона",
        "value": ["белый"]
      }, {
        "name": "Максимальная мощность ламп, W",
        "value": ["60"]
      }, {"name": "Тип цоколя ламп", "value": ["E27"]}, {
        "name": "Количество ламп",
        "value": ["2"]
      }, {"name": "Материалы", "value": ["полимер", "PMMA"]}, {
        "name": "Общее количество ламп",
        "value": ["2"]
      }, {"name": "Ширина, см", "value": ["60"]}, {"name": "Высота, см", "value": ["37"]}, {
        "name": "Цвет арматуры",
        "value": ["белый"]
      }, {"name": "Вес, г", "value": ["3350"]}, {"name": "Степень защиты", "value": ["IP55"]}, {
        "name": "Стиль",
        "value": ["Современный"]
      }, {"name": "Производитель", "value": ["Fumagalli"]}, {
        "name": "Дополнительные характеристики",
        "value": ["корпус изготовлен из современного композитного полимера RESIN, прочного, окрашенного в массе, не ржавеющего и не выгорающего на солнце; в комплекте идут крепежи и закладные элементы; рассеиватель выполнен из антивандального, не мутнеющего и не горючего PMMA; срок службы светильника - не менее 10 лет; температура использования от +60 до -90; столбы выше 150 см имеют в основе мощную стальную трубу с двойной оцинковкой и полимерным наполнителем; способ крепления - монтажная пластина"]
      }, {"name": "Плафоны", "value": ["пластик (PMMA)"]}, {
        "name": "Место применения",
        "value": ["Улица", "Веранда"]
      }, {"name": "Вес брутто", "value": ["3.35"]}
    ]
  },
  {
    "id": "73759",
    "name": "Уличный настенный светильник Globe",
    "model": "Пятый артикул",
    "quantity": "10",
    "regularPrice": "15636.0000",
    "image": "https:\/\/www.svetlux.ru\/images\/pics1\/products\/f\/d\/0\/d\/fd0d80145c76d98ce483012947cd6c17.jpg",
    "manufacturerName": "Fumagalli",
    "manufacturerCountry": "Италия",
    "categoriesName": "серия GLOBE 250 WHITE 2 FU",
    "categoriesId": "29541",
    "description": "Уличный настенный светильник Globe G25.141.000.WYE27 от итальянского бренда Fumagalli. Данный светильник серии GLOBE 250 WHITE 2 FU отличает неповторимый дизайн, который может стать важным акцентом уличного освещения.<br \/>\nРазмеры: Высота - 37 см; Ширина - 60 см.<br \/>\nБренд Fumagalli оснастил данную модель лампами с цоколем E27 и максимальной мощностью 60 Ватт. Подключив светильник, Вы получите хорошее уличное освещение.<br \/>\nЦвет арматуры уличного настенного светильника Globe G25.141.000.WYE27 - белый.",
    "images": [
      "https:\/\/www.svetlux.ru\/images\/pics1\/products\/b\/f\/6\/7\/bf677424e580c708d015a5326e0acdd8.jpg",
      "https:\/\/www.svetlux.ru\/images\/pics1\/products\/b\/3\/6\/f\/b36f64432fff84c506425c9a7d2c1bc5.jpg"],
    "information": [
      {
        "name": "Комплектация лампами",
        "value": ["да"]
      },
      {
        "name": "Общая мощность, W",
        "value": ["120"]
      }, {
        "name": "Цвет плафона",
        "value": ["белый"]
      }, {
        "name": "Максимальная мощность ламп, W",
        "value": ["60"]
      }, {"name": "Тип цоколя ламп", "value": ["E27"]}, {
        "name": "Количество ламп",
        "value": ["2"]
      }, {"name": "Материалы", "value": ["полимер", "PMMA"]}, {
        "name": "Общее количество ламп",
        "value": ["2"]
      }, {"name": "Ширина, см", "value": ["60"]}, {"name": "Высота, см", "value": ["37"]}, {
        "name": "Цвет арматуры",
        "value": ["белый"]
      }, {"name": "Вес, г", "value": ["3350"]}, {"name": "Степень защиты", "value": ["IP55"]}, {
        "name": "Стиль",
        "value": ["Современный"]
      }, {"name": "Производитель", "value": ["Fumagalli"]}, {
        "name": "Дополнительные характеристики",
        "value": ["корпус изготовлен из современного композитного полимера RESIN, прочного, окрашенного в массе, не ржавеющего и не выгорающего на солнце; в комплекте идут крепежи и закладные элементы; рассеиватель выполнен из антивандального, не мутнеющего и не горючего PMMA; срок службы светильника - не менее 10 лет; температура использования от +60 до -90; столбы выше 150 см имеют в основе мощную стальную трубу с двойной оцинковкой и полимерным наполнителем; способ крепления - монтажная пластина"]
      }, {"name": "Плафоны", "value": ["пластик (PMMA)"]}, {
        "name": "Место применения",
        "value": ["Улица", "Веранда"]
      }, {"name": "Вес брутто", "value": ["3.35"]}
    ]
  },
  {
    "id": "73759",
    "name": "Уличный настенный светильник Globe",
    "model": "Шестой артикул",
    "quantity": "10",
    "regularPrice": "15636.0000",
    "image": "https:\/\/www.svetlux.ru\/images\/pics1\/products\/f\/d\/0\/d\/fd0d80145c76d98ce483012947cd6c17.jpg",
    "manufacturerName": "Fumagalli",
    "manufacturerCountry": "Италия",
    "categoriesName": "серия GLOBE 250 WHITE 2 FU",
    "categoriesId": "29541",
    "description": "Уличный настенный светильник Globe G25.141.000.WYE27 от итальянского бренда Fumagalli. Данный светильник серии GLOBE 250 WHITE 2 FU отличает неповторимый дизайн, который может стать важным акцентом уличного освещения.<br \/>\nРазмеры: Высота - 37 см; Ширина - 60 см.<br \/>\nБренд Fumagalli оснастил данную модель лампами с цоколем E27 и максимальной мощностью 60 Ватт. Подключив светильник, Вы получите хорошее уличное освещение.<br \/>\nЦвет арматуры уличного настенного светильника Globe G25.141.000.WYE27 - белый.",
    "images": [
      "https:\/\/www.svetlux.ru\/images\/pics1\/products\/b\/f\/6\/7\/bf677424e580c708d015a5326e0acdd8.jpg",
      "https:\/\/www.svetlux.ru\/images\/pics1\/products\/b\/3\/6\/f\/b36f64432fff84c506425c9a7d2c1bc5.jpg"],
    "information": [
      {
        "name": "Комплектация лампами",
        "value": ["да"]
      },
      {
        "name": "Общая мощность, W",
        "value": ["120"]
      }, {
        "name": "Цвет плафона",
        "value": ["белый"]
      }, {
        "name": "Максимальная мощность ламп, W",
        "value": ["60"]
      }, {"name": "Тип цоколя ламп", "value": ["E27"]}, {
        "name": "Количество ламп",
        "value": ["2"]
      }, {"name": "Материалы", "value": ["полимер", "PMMA"]}, {
        "name": "Общее количество ламп",
        "value": ["2"]
      }, {"name": "Ширина, см", "value": ["60"]}, {"name": "Высота, см", "value": ["37"]}, {
        "name": "Цвет арматуры",
        "value": ["белый"]
      }, {"name": "Вес, г", "value": ["3350"]}, {"name": "Степень защиты", "value": ["IP55"]}, {
        "name": "Стиль",
        "value": ["Современный"]
      }, {"name": "Производитель", "value": ["Fumagalli"]}, {
        "name": "Дополнительные характеристики",
        "value": ["корпус изготовлен из современного композитного полимера RESIN, прочного, окрашенного в массе, не ржавеющего и не выгорающего на солнце; в комплекте идут крепежи и закладные элементы; рассеиватель выполнен из антивандального, не мутнеющего и не горючего PMMA; срок службы светильника - не менее 10 лет; температура использования от +60 до -90; столбы выше 150 см имеют в основе мощную стальную трубу с двойной оцинковкой и полимерным наполнителем; способ крепления - монтажная пластина"]
      }, {"name": "Плафоны", "value": ["пластик (PMMA)"]}, {
        "name": "Место применения",
        "value": ["Улица", "Веранда"]
      }, {"name": "Вес брутто", "value": ["3.35"]}
    ]
  },
  ]
