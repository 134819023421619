import {useEffect, useRef, useState} from 'react';
import './styles/GalleryCards.css'
import "react-image-gallery/styles/css/image-gallery.css";
import {getProductById} from "../../utils/illumination/api";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import left from "../../UI/icons/arrowLeft.svg"
import close from "../../UI/icons/close.svg"

let settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  lazyLoad: true,
  autoplaySpeed: 5000,
  autoplay: true,
  arrows: false,
  adaptiveHeight: true,
};

export default function GalleryCard({id}) {
  const [slideImage, setSlideImage] = useState([])
  let sliderRef = useRef();
  const [isFullscreen, setIsFullscreen] = useState(false);

  function openImage () {
    setIsFullscreen(true)
  }
  function closeImage () {
    setIsFullscreen(false)
  }
  useEffect(() => {
    async function getProd(){
      // setIsLoading(true)
      await getProductById({id: id.toString()})
        .then(product => {
          setSlideImage([])
          let images = []
          setSlideImage(initial => [...initial, {original: product.image, thumbnail: product.image, originalHeight: 350}])
          if (product.images.length === 0) {
            images.map((el, i) => {
              setSlideImage(initial => [...initial, {original: el, thumbnail: el, originalHeight: 350}])
            })
          }else{
            images = product.images
            images.map((el, i) => {
              setSlideImage(initial => [...initial, {original: el, thumbnail: el, originalHeight: 350}])
            })
          }
        })
        .catch(console.error)
    }
    getProd()
  }, [id])

  const next = () => {
    sliderRef.slickNext();
  }
  const prev = () => {
    sliderRef.slickPrev();
  }
  return (
    <div className={`${isFullscreen ? 'gallery-photos_active' : 'gallery-photos'}`}>
      {
        isFullscreen
          ?
          <button
            onClick={closeImage}
            className="fullscreen__close">
            <img src={close} fill="white" alt="arrow" className="fullscreen__close-image"/>
          </button>
          :
          null
      }
      <button onClick={prev} className={`${isFullscreen ? 'gallery__prev-wrapper gallery__prev-wrapper_active' : 'gallery__prev-wrapper'}`}>
        <img src={left} alt="arrow" className="gallery__prev"/>
      </button>
      <Slider {...settings} ref={slider => (sliderRef = slider)} className={`${isFullscreen ? 'product-slider_active' : 'product-slider'}`}>
        {
          slideImage?.map((galleryElem, id) =>
            <div
              key={id}
              className={`${isFullscreen ? 'card__wrapper_active' : 'card__wrapper'}`}
            >
              <img
                onClick={openImage}
                className={`${isFullscreen ? 'gallery__card_active' : 'gallery__card'}`}
                src={galleryElem.original}
              />
            </div>)
        }
      </Slider>
      <button onClick={next} className={`${isFullscreen ? 'gallery__prev-wrapper gallery__prev-wrapper_active' : 'gallery__prev-wrapper'}`}>
        <img src={left} fill="white" alt="arrow" className="gallery__next"/>
      </button>

    </div>
  )
}




