import {typeOfLamp} from "./typeOfLamp";
import {categories} from "./categories";

export const initialFormData = {
  'subCategories': [typeOfLamp],
  'subCategory': [typeOfLamp],
  'categories': categories,
  'regularPrice': {
    'min': '',
    'max': '',
  },
  'diameter': {
    'min': '',
    'max': '',
  },
  'height': {
    'min': '',
    'max': '',
  },
  'length': {
    'min': '',
    'max': '',
  },
  'information': [],
  'manufacturersAndLands': [],
  'noRes': false
}
