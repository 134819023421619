import './SubDoorsCategoryList.css'
import {Link, useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {
  getCatalogById,
  getTailProductsDoorsParentLevel, getTailProductsDoorsSubCategoryLevel
} from "../../../utils/doors/apiDoors";
import AccordionCategory from "../../Accordion/Doors/AccordionCategory/AccordionCategory";
import Preloader from "../../Preloader/Preloader";
import {
  CATEGORIES,
  CATEGORY_ID,
  COLLECTIONS,
  DOORS,
  PAGE_ID,
  PARENT_CATEGORY_ID, SUB_CATEGORY_ID,
  VIEW_COLLECTIONS
} from "../../../constants/constants";
import PaginationComp from "../../Pagination/Pagination";
import parserPath from "../../../utils/parserPath";
import calcRangeList from "../../../utils/calcRangeList";
import PreviewDoor from "../../Preview/PreviewDoor/PreviewDoor";
import {getValueFromPath} from "../../../utils/doors/getCategoryId";

export default function SubDoorsCategoryList({isLoading, setIsLoading, addToBasket}) {
  const [parentCategory, setParentCategory] = useState({})
  const [productList, setProductList] = useState([])
  const location = useLocation()
  const [collectionListLength, setCollectionListLength] = useState(0)
  const navigate = useNavigate();

  let parentCategoryId = getValueFromPath(location.pathname, PARENT_CATEGORY_ID)
  let categoryId = getValueFromPath(location.pathname, CATEGORY_ID)
  let pageId = getValueFromPath(location.pathname, PAGE_ID).toString()

  useEffect(() => {
    setIsLoading(true);
    window.scrollTo(0, 0);
    let parentCategoryId = getValueFromPath(location.pathname, PARENT_CATEGORY_ID)
    let categoryId = getValueFromPath(location.pathname, SUB_CATEGORY_ID)

    let pageId = getValueFromPath(location.pathname, PAGE_ID)
    let {start, end} = calcRangeList(pageId)
    Promise.all([
      getCatalogById(parentCategoryId),
      getTailProductsDoorsSubCategoryLevel({start, end, categoryId})
    ])
      .then(([parentCategoryResponse, tailProductsResponse]) => {
        setParentCategory(parentCategoryResponse);
        setProductList(tailProductsResponse.products)
        setCollectionListLength(tailProductsResponse.listLength)
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => setTimeout(() => setIsLoading(false), 500));
  }, [location.pathname]);

  function goBack(){
    navigate(-1)
  }

  return (
    <div className="doors-category__wrapper">
      <button className="product-door__go-back1" onClick={goBack}>назад</button>
      <ul className="custom-accordion__list">
        {
          parentCategory?.category?.map(el => {
            return (
              <li className="custom-accordion__item">
                <AccordionCategory key={el.id} categoryName={el.name} subcategory={el.subCategory} data={el}
                                   setIsLoading={setIsLoading}/>
              </li>
            )
          })
        }
      </ul>
      <div className="products__category">
        <div className="products__list">
          {productList?.map((el, i) => {
            return <PreviewDoor key={i} id={el.id} cardData={el} addToBasket={addToBasket}/>
          })
          }
        </div>
        <PaginationComp active={pageId} listLength={collectionListLength}
                        path={`${DOORS}/${PARENT_CATEGORY_ID}=${parentCategoryId}/${CATEGORY_ID}=${categoryId}`}
                        categoryName={SUB_CATEGORY_ID}/>
      </div>
    </div>
  )
}
