import './Header.css'
import {Cart, ThreeDotsVertical} from "react-bootstrap-icons";
import logo from '../../UI/images/logo.png'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {useState} from "react";
import NavbarMobile from '../NavbarsMobile/NavbarMobile';
export default function Header(
  {
    goSearch,
    basketData,
    hideModal,
    show,
    showmodal,
    mobileIsFilterOrCategory,
    counter,
    setCounter,
    setFormDataForFilter,
    setUpt
  }) {
  const [searchValue, setSearchValue] = useState("");

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container fluid>
          <Navbar.Brand href="/"><img src={logo} alt="Мирадек" width="238"/></Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll"/>
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{maxHeight: '150px'}}
              navbarScroll
            >
              <Nav.Link href="/delivery">Доставка</Nav.Link>
              <Nav.Link href="/contacts">Контакты</Nav.Link>
              <Nav.Link href="/vendors">Производители</Nav.Link>
              <Nav.Link href="/payment">Оплата</Nav.Link>
            </Nav>
            {/* Cart */}
            <Nav.Link href={basketData.length >= 1 ? '/basket' : ''}>
              <div className="cart">
                <div className="cart-icon">
                  <Cart size={25} color='gray' className="menu__item__image"/>
                  <span>Корзина</span>
                </div>
                {basketData.length > 0 && <i className='cart-count'>{basketData.length}</i>}
              </div>
            </Nav.Link>
            <Form className="d-flex" onSubmit={(e) => goSearch(e, searchValue)}>
              <Form.Control
                type="search"
                placeholder="Поиск по сайту..."
                className="me-2"
                aria-label="Search"
                value={searchValue}
                onChange={handleInputChange}
              />
              <Button type="submit" variant="warning">Найти</Button>
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* Mobile only */}
      <Nav.Link href={basketData.length >= 1 ? '/basket' : ''}>
        <div className="cart-mobile">
          <div className="cart-icon">
            <Cart size={25} color='gray' className="menu__item__image"/>
            <span>Корзина</span>
          </div>
          {basketData.length > 0 && <i className='cart-count'>{basketData.length}</i>}
        </div>
      </Nav.Link>
      {/*  Каталог товаров */}
      <div className="navbar-mobile navbar__mobile">
        <div className="navbar__item">
          <ThreeDotsVertical size={25} color='gray' className="navbar-mobile__icon"/>
          <span id="category" onClick={showmodal}>Каталог товаров</span>
        </div>
        <div className="navbar__item">
          <ThreeDotsVertical size={25} color='gray' className="navbar-mobile__icon"/>
          <span id="filter" onClick={showmodal}>Фильтр</span>
        </div>

      </div>
      <NavbarMobile
        show={show}
        onHide={hideModal}
        showModal={showmodal}
        mobileIsFilterOrCategory={mobileIsFilterOrCategory}
        counter={counter}
        setCounter={setCounter}
        setFormDataForFilter={setFormDataForFilter}
        setUpt={setUpt}
      />
    </>
  )
}
