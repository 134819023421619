import TemplateTable from "./TemplateTable/TemplateTable";

export default function CategoryTable({information, model}) {
  const categories = {
    'Основное': ['Производитель', 'Страна', 'Стиль', 'Коллекция', 'Тип светильника', 'Вид светильника', 'Место применения', 'Номенклатура'],
    'Внешний вид': ['Материалы', 'Цвет арматуры', 'Цвет плафона', 'Подвески', 'Плафоны', 'Декор'],
    'Размеры': ['Высота, см', 'Ширина, см', 'Диаметр, см', 'Длина, см', 'Минимальная высота, см', 'Диаметр монтажного отверстия, см', 'Выступ от стены, см', 'Длина шнура, см', 'Высота плафона, см'],
    'Электрика': ['Количество ламп', 'Тип цоколя ламп', 'Максимальная мощность ламп, W', 'Общая мощность, W', 'Мощность, W', 'Комплектация лампами', 'Общее количество ламп', 'Напряжение', 'Общая мощность, W', 'Световой поток, lm', 'Соответствие лампе накаливания, Вт', 'Цветовая температура'],
    'Дополнительные параметры': ['Степень защиты', 'Вес, г', 'Дополнительные характеристики', 'Гарантийный срок, год', 'Способ крепления', 'Выключатель', 'Возможность использования с диммером', 'Угол рассеивания света, градусы']
  };

  const categorizedData = Object.keys(categories).map(categoryName => {
    const categoryFields = categories[categoryName];
    const categoryData = information.filter(item => categoryFields.includes(item.name));
    return {categoryName, categoryData};
  });

  return (
    <div>
      {categorizedData.map(category => (
        <TemplateTable key={category.categoryName} categoryName={category.categoryName} data={category.categoryData}
                       model={model}/>
      ))}
    </div>
  )
}
