import './Collections.css'
import {Link, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {getCollectionsListById} from "../../utils/illumination/api";
import PreviewCollection from "../PreviewCollection/PreviewCollection";
import Preloader from "../Preloader/Preloader";
import parserPath from "../../utils/parserPath";
import calcRangeList from "../../utils/calcRangeList";
import PaginationComp from "../Pagination/Pagination";
import {CATEGORIES, CATEGORY_ID, COLLECTIONS, VIEW_PRODUCTS} from "../../constants/constants";

export default function Collections({isLoading, setIsLoading}) {
  const [collectionList, setCollectionList] = useState([])
  const [collectionListLength, setCollectionListLength] = useState(0)
  const location = useLocation();

  useEffect(_ => {
    setIsLoading(true)
    window.scrollTo(0, 0);
    const {categoryId, pageId} = parserPath(location.pathname)
    const {start, end } = calcRangeList(pageId)
      getCollectionsListById(categoryId, start, end)
        .then(res => {
          setCollectionList(res.collections)
          setCollectionListLength(res.listLength)
        })
        .finally(() => setTimeout(() => setIsLoading(false), 500))

  }, [location.pathname])

  return (
    <>
    {
      isLoading ? <Preloader/> :
        <div className="products__category">
          <Link to={`/${CATEGORIES}/${CATEGORY_ID}=${parserPath(location.pathname).categoryId}&pageId=${parserPath(location.pathname).pageId}`} className="btn btn-warning btn-collection">{VIEW_PRODUCTS}</Link>
          <div className="products__list">
            {collectionList?.map((el, i) => {
              return <PreviewCollection key={i} rout={parserPath(location.pathname).pageId} data={el} />
            })
            }
          </div>
          <PaginationComp active={parserPath(location.pathname).pageId} listLength={collectionListLength} path={COLLECTIONS} categoryName={CATEGORY_ID}/>
        </div>
    }
    </>
  )
}
