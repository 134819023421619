import './Checkbox.css'

export default function Checkbox({...props}) {
  return (
    <label className="checkbox-wrapper">
      <input className="checkbox__input" type="checkbox" {...props} />
      <span className="checkbox__checkmark"></span>
      <p className="checkbox__name">{props.name}</p>
    </label>
  )
}
