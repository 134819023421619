import './Hero.css'
import React from "react";
import {navBarList} from "../../constants/navBarList";
import Category from "../CategoryItem/Category";
import {CATEGORY_ID, PAGE_ID} from "../../constants/constants";

export default function Hero() {
  return (
    <div className="hero">
      {
        navBarList?.map(
          item => {
            return (
              <Category
                key={item.id}
                name={item.name}
                link={`${CATEGORY_ID}=${item.id}&${PAGE_ID}=1`}
                imageType={item.link}
              />)
          })
      }
    </div>
  )
}



