import './PreviewDoor.css';
import {Link, useLocation} from "react-router-dom";
import {Cart} from "react-bootstrap-icons";
export default function PreviewDoor({id, cardData, addToBasket}) {
  const {price, image, title, model, quantity} = cardData


  return(
    <Link to={`/doors/product/productId=${id}`} className="preview-wrapper">
      <div className="preview">
        <img src={image} alt="превью" className="preview__image"/>
        <h3 className="preview__name">{title}</h3>
        <div className="preview__to">
          <div className="preview__price">
            <span className="special__price">{price}</span>
          </div>

          <button
            type="button"
            className="btn btn-warning basket-btn"
            onClick={(event) => addToBasket(event, title, model, price, image, 1, quantity)}
          >
            <Cart size={25} color='white' className="menu__item__image" style={{ verticalAlign: 'middle', margin: 0 }}/>
          </button>

        </div>
      </div>
    </Link>

  )
}
