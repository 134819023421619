import './ProductDoor.css'
import {Link, useLocation, useNavigate} from "react-router-dom"
import {useEffect, useState} from 'react';
import {getValueFromPath} from "../../../utils/doors/getCategoryId";
import {PARENT_CATEGORY_ID, PRODUCT_ID} from "../../../constants/constants";
import Preloader from "../../Preloader/Preloader";
import {getProductDoorById, getProductsInComplectDoorByIds} from "../../../utils/doors/apiDoors";

export default function ProductDoor({isLoading, setIsLoading, addToBasket}) {
  const location = useLocation()
  const navigate = useNavigate();
  const id = getValueFromPath(location.pathname, PRODUCT_ID)
  const [equalProducts, setEqualProducts] = useState([])
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [basketCount, setBasketCount] = useState(1)
  const [colorList, setColorList] = useState([])
  const [sizeList, setSizeList] = useState([])
  const [productsInComplectList, setProductsInComplectList] = useState([])

  function chooseParams(type, value) {
    let color, size, targetProduct;

    if (type === 'color') {
      color = value;
      size = selectedProduct.size;
      if (!color) {
        targetProduct = equalProducts.find(product => product.size === size);
      } else {
        targetProduct = equalProducts.find(product => product.color?.image === color && product.size === size);
      }
    } else {
      size = value;
      color = selectedProduct.color?.image;
      if (!size) {
        targetProduct = equalProducts.find(product => product.color?.image === color);
      } else {
        targetProduct = equalProducts.find(product => product.color?.image === color && product.size === size);
      }
    }

    if (targetProduct) {
      navigate(`/doors/product/productId=${targetProduct.id}`);
    } else {
      console.error("No matching product found");
    }
  }

  useEffect(() => {
    setIsLoading(true)
    window.scrollTo(0, 0);
    getProductDoorById(id)
      .then(res => {
        setEqualProducts(res.products)
        res.productById.price = convertHeadPrice(res.productById.price)
        setSelectedProduct(res.productById)
        const colorSet = new Set();
        const sizeSet = new Set();
        res.products.forEach(product => {
          if (product.color?.image) {
            colorSet.add(product.color.image)
          }
          if (product.size) {
            sizeSet.add(product.size)
          }
        });
        const colors = Array.from(colorSet);
        const sizes = Array.from(sizeSet);
        setColorList(colors)
        setSizeList(sizes)

        if (res.productById.constructorData.length > 0) {
          const ids = res.productById.constructorData.map(el => el.id)
          getProductsInComplectDoorByIds(ids)
            .then(res => {
              res.map(product => {
                product.price = convertHeadPrice(product.price)
              })
              setProductsInComplectList(res)
            })
            .catch(console.error)
        }
      })
      .catch(console.error)
      .finally(() => setTimeout(() => setIsLoading(false), 500))
  }, [location.pathname])

  const addToBasketHandlerSingleProduct = (event) => {
    const data = {
      type: "door",
      name: selectedProduct?.title,
      article: selectedProduct?.id,
      price: selectedProduct.price + ' руб.',
      image: selectedProduct?.image,
      quantity: selectedProduct.quantity,
      count: basketCount,
      isComplect: false,
    }
    addToBasket(event, data, basketCount)
    setBasketCount(1)
  }

  function goBack(){
    navigate(-1)
  }

  if (!selectedProduct) return null

  function getTotalPrice() {
    let totalPrice = 0;
    productsInComplectList?.forEach(el => {
      selectedProduct.constructorData?.forEach(descEl => {
        if (el.id === descEl.id) {
          const price = parseFloat(el.price.replace(/\s/g, '').replace(',', '.'));
          const quantity = parseFloat(descEl.quantity.replace(/\s/g, '').replace(',', '.'));
          totalPrice += price * quantity;
        }
      });
    })
    let selectProductPrice = parseFloat(selectedProduct.price.replace(/\s/g, '').replace(',', '.'));
    totalPrice += selectProductPrice
    return totalPrice;
  }

  function convertHeadPrice(price){
    let selectProductPrice = parseFloat(price.replace(/\s/g, '').replace(',', '.'));
    let priceWithDiscount = selectProductPrice * 0.98; // Вычитаем 2% (или умножаем на 0.98)
    let priceWithoutFraction = Math.floor(priceWithDiscount);
    return formatPrice(priceWithoutFraction);
  }

  function formatPrice(price) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  return (
    <>
      {
        isLoading ?
          <div className="product-door__container">
            <div className="product-door__preloader">
              <Preloader/>
            </div>
          </div>
          :
          <div className="product-door__container">
            <button className="product-door__go-back" onClick={goBack}>назад</button>
            <img src={selectedProduct.image} alt="изображение" className="product-door__image"/>
            <h2 className="product-door__title">{selectedProduct.title}</h2>
            <div className="product-door__information">
              <p className="product-door__price">Цена: {selectedProduct.price} руб.</p>
              <p className="product-door__quantity">{selectedProduct.quantity}</p>
              <ul className="door-color">
                {colorList.map((image, i) => (
                  <li onClick={(e) => chooseParams('color', image)} key={i} className="door-color__item">
                    <img className="door-color__image" src={image} alt="цвет"/>
                  </li>
                ))}
              </ul>
              <ul className="door-size">
                {sizeList.map((size, i) => (
                  <li onClick={(e) => chooseParams('size', size)} key={i}
                      className={`door-size__item ${size === selectedProduct.size ? 'door-size__item_active' : ''}`}>
                    <p className="door-size__description">
                      {size}
                    </p>
                  </li>
                ))}
              </ul>
              <form className="calculator__form mb-1" onSubmit={(event) => addToBasketHandlerSingleProduct(event)}>
                <input
                  className="calculator__count"
                  type="number"
                  placeholder="1"
                  min="1"
                  value={basketCount}
                  onChange={(event) => setBasketCount(event.target.value)}
                />
                <button type="submit" className="btn btn-warning calculator__submit"
                        style={{borderRadius: '12px'}}>Добавить
                  в корзину
                </button>
              </form>
              <ul className="characteristics-door">
                {
                  selectedProduct.properties?.map((el, i) => (
                    <li key={i} className="characteristics-door__item">
                      <p className="characteristics-door__value">
                        <span className="characteristics-door__name">{el.name}:    </span>
                        {el.value}
                      </p>
                    </li>
                  ))
                }
              </ul>
            </div>

            {
              selectedProduct?.constructorData.length > 0 &&
              <div className="complect">
                <h3 className="complect__title">Купить комплект</h3>
                <div className="complect__wrapper">
                  <ul className="door-add-product">
                    <li key={1} className="door-add-product__item">
                      <img className="door-add-product__image" src={selectedProduct.image} alt="цвет"/>
                      <h3 className="door-add-product__title">{selectedProduct.title}</h3>
                      <p className="door-add-product__quantity">{selectedProduct.price} руб. * 1 шт.</p>
                    </li>
                    {
                      productsInComplectList?.map((el, i) => {
                        let counter
                        selectedProduct.constructorData?.filter(descEl => {
                          if (el.id === descEl.id) {
                            counter = descEl.quantity
                          }
                        })
                        return (
                          <li key={i + 1} className="door-add-product__item">
                            <img className="door-add-product__image" src={el.image} alt="цвет"/>
                            <h3 className="door-add-product__title">{el.title}</h3>
                            <p className="door-add-product__quantity">{el.price + ' руб. * ' + counter} шт.</p>

                          </li>
                        )
                      })
                    }
                  </ul>
                  <div className="door-product__total-price">
                    <form className="calculator__form" onSubmit={(event) => addToBasketHandlerSingleProduct(event)}>
                      <input
                        className="calculator__count"
                        type="number"
                        placeholder="1"
                        min="1"
                        value={basketCount}
                        onChange={(event) => setBasketCount(event.target.value)}
                      />
                      <button type="submit" className="btn btn-warning calculator__submit"
                              style={{borderRadius: '12px'}}>Добавить
                        в корзину
                      </button>
                    </form>
                    <h3 className="door-product__total-title">Цена за 1 комплект: {getTotalPrice()} руб.</h3>
                  </div>
                </div>
              </div>
            }
          </div>
      }
    </>
  )
}


