import './SearchList.css'
import React, {useEffect, useState} from "react";
import Preview from "../Preview/Preview";
import Preloader from '../Preloader/Preloader';
import {search} from "../../utils/illumination/api";
import {useLocation} from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import NoResults from "../NoResults/NoResults";
export default function SearchList() {
  const navigate = useNavigate()
  const [end, setEnd] = useState(30)
  const [searchList, setSearchList] = useState([])
  let {pathname} = useLocation()
  const pathnameList = pathname.split('/')
  const id = pathnameList[pathnameList.length - 1]

  function goBack(){
    navigate(-1)
  }

  useEffect(_=> {
    search(id)
      .then(res => setSearchList(res))
  },[id])

  return (
    <>
      {
        searchList.length === 0 ? <Preloader /> :
          <div className="products__list">
            {searchList[0] === 'no-result'
              ?
              <NoResults title="Поиск не дал результатов" goBack={goBack}/>
              :
              searchList.map((el, i) => {
              if (i > end) {
                return null
              }
              return <Preview key={i} id={el.id} cardData={el}/>
            })
          }
          </div>
      }
    </>
  )
}
