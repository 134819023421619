import './styles/DeliveryPage.css'
import { useLocation } from 'react-router-dom'

export default function DeliveryPage() {
  return (
    <div className="delivery__page">
      <div className="page__title">Доставка</div>

      <p>На формирование заказа нам требуется <b>1-2 рабочих дня</b> в зависимости от заказанного товара.</p>
      <p>Доставку товара по Москве и Московской области осуществляет наша собственная курьерская служба. При доставке мы всегда учитываем удобное для Вас время.</p>

      <table className="table table-bordered">
        <thead>
          <tr>
            <td>
              <b>ДОСТАВКА<b></b></b>
            </td>
            <td>
              <b>Заказ до 1&nbsp;000&nbsp;<i className="fa fa-rub"></i></b>
            </td>
            <td>
              <b>Заказ 1&nbsp;000 - 2999&nbsp;<i className="fa fa-rub"></i></b>
            </td>
            <td>
              <b>Более 3&nbsp;000&nbsp;<i className="fa fa-rub"></i></b>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <b>Москва в пределах МКАД + 5 км</b>
            </td>
            <td>
              400&nbsp;<i className="fa fa-rub"></i>
            </td>
            <td>
              280&nbsp;<i className="fa fa-rub"></i>
            </td>
            <td>
              <b>БЕСПЛАТНО<b></b></b>
            </td>
          </tr>
          <tr>
            <td>
              <b>Москва и МО за пределами МКАД + 5 км<b></b></b>
            </td><td>
              400&nbsp;<i className="fa fa-rub"> + 20&nbsp;<i className="fa fa-rub"></i>/км</i>
            </td>
            <td>
              280&nbsp;<i className="fa fa-rub"> + 20&nbsp;<i className="fa fa-rub"></i>/км</i>
            </td>
            <td>
              20&nbsp;<i className="fa fa-rub"></i>/км
            </td>
          </tr>
        </tbody>
      </table>

      <p><b>Доставка по Ступино осуществляется бесплатно.</b> Также товар можно забрать самовывозом по адресу МО, г. Ступино, ул. Чайковского, д.58. Мы работаем ежедневно с 10:00 до 19:00.</p>
      <p>Доставка по Ступинскому, Каширскому и Озёрскому районам обговаривается индивидуально.</p>
      <p>Если Вам необходимо получить товар в другом городе – мы можем доставить его до транспортной компании в Москве. В этом случае вы оплачиваете нам только стоимость доставки по Москве, а стоимость доставки от Москвы до Вашего города оплачиваете транспортной компании при получении товара.</p>
      <p><b>Внимание:</b> оплата товара при отправке в другие города производится только предоплатой по выставляемому нами счету.</p>
    </div>
  )
}
