const baseUrl = 'https://miradec.ru/api/'
// const baseUrl = 'https://miradec.ru/test/api/'
// const baseUrl = 'http://localhost:3002/test/api/'

function checkResponse(res) {
  return res.ok ? res.json() : Promise.reject(res);
}

//получить child категории по id родителя
export function getSubtype(parentId){
  let id
  if(parentId === undefined){
    id = '0'
  }else{
    id = parentId
  }
  return fetch(`${baseUrl}get-child-categories`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({id})
    })
      .then(checkResponse)
}

export function getManufacturers() {
  return fetch(`${baseUrl}manufacturers`)
    .then(checkResponse)
}

export function productsByManufacturerId(id, start, end) {
  return fetch(`${baseUrl}products-by-manufacturers`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({id, start, end})
  })
    .then(checkResponse)
}


//получить список id коллекциий по родительской категории
export function getCollectionsListById(categoryId, start, end) {
  return fetch(`${baseUrl}collections`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({categoryId, start, end})
  })
    .then(checkResponse);
}

export function getCollectionsByManufacturersId(id, start, end) {
  return fetch(`${baseUrl}collections-by-id-manufacturers`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({id, start, end})
  })
    .then(checkResponse);
}

export function getTopicProduct() {
  return fetch(`${baseUrl}topic`)
    .then(checkResponse)
}

export function sendMail(formData) {
  return fetch(`${baseUrl}send-mail`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(formData)
  })
    .then(checkResponse);
}

export function search(searchString) {
  return fetch(`${baseUrl}search-by-article`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({searchString})
  })
    .then(checkResponse);
}

export function getTailProducts({start, end, categoryId: categoriesId}) {
  const data = {
    start,
    end,
    categoriesId
  };
  return fetch(`${baseUrl}products-categories`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(checkResponse);
}

export function productsCategoriesCoins(){
  return fetch(`${baseUrl}products-categories-coins`)
    .then(checkResponse)
}


export function searchLampsForProduct(id) {
  return fetch(`${baseUrl}search-lamps-for-product`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({id})
  })
    .then(checkResponse);
}

export function getProductsByCollection({collectionId}) {
  const data = {
    collectionId
  };
  return fetch(`${baseUrl}products-by-collection`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(checkResponse);
}

export function getProductById({id}) {
  const data = {
    id
  };
  // console.log('data: ', data)
  return fetch(`${baseUrl}product-by-id`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(checkResponse);
}

export function getProductsFromThisCollection({categoriesId}) {
  const data = {
    categoriesId
  };
  return fetch(`${baseUrl}get-products-from-this-collection`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(checkResponse);
}
