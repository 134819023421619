import {Modal} from 'react-bootstrap'
import CategoryItem from "../CategoryItem/CategoryItem"
import {navBarList} from "../../constants/navBarList"
import React from "react";
import FilterMobile from "../Filter/FilterMobile";
import {CATEGORY_ID, PAGE_ID} from "../../constants/constants";

export default function NavbarMobile(props) {
  const {counter, setCounter, setFormDataForFilter, setUpt} = props
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton/>
      {
        props.mobileIsFilterOrCategory === 'category' ?
          <nav className="left-menuq">
            {
              navBarList?.map(
                item => {
                  return (<CategoryItem
                    key={item.id}
                    name={item.name}
                    link={`${CATEGORY_ID}=${item.id}&${PAGE_ID}=1`}
                    imageType={item.link}
                    hideModal={props.onHide}
                  />)
                })
            }
          </nav>
          :
          <FilterMobile
            counter={counter}
            setCounter={setCounter}
            setFormDataForFilter={setFormDataForFilter}
            setUpt={setUpt}
            hideModal={props.onHide}
            show={props.mobileNavbarShow}
            showmodal={props.showmodal}
          />
      }


    </Modal>
  )
}
