import './PreviewCollection.css'
import priceCalculation from "../../utils/priceCalculation";
import {Link, useLocation} from "react-router-dom";

export default function PreviewCollection({data, rout, isFromFilter}) {
  const {pic, name, parent, collectionId} = data
  return(
    <Link to={`/collection/${rout}/${collectionId}`} state={{data}} className="preview-wrapper">
      <div className="preview-collection">
        <img src={pic} alt="" className="preview-collection__image"/>
        <h3 className="preview-collection__name">{name}</h3>
      </div>
    </Link>

  )
}
