import './DoorsMain.css'
import React, {useEffect, useState} from "react";
import {getCatalog} from "../../../utils/doors/apiDoors";
import CategoryDoors from "../../../components/CategoryItem/CategoryDoors/CategoryDoors";
import {COLLECTIONS, PARENT_CATEGORY_ID} from "../../../constants/constants";
import {useNavigate} from "react-router-dom";

export default function DoorsMain() {
  const [parentCategories, setParentCategories] = useState([])
  const navigate = useNavigate();
  function goBack() {
    navigate(-1)
  }

  useEffect(() => {
    getCatalog()
      .then(setParentCategories)
      .catch(console.error)
  }, []);

  return (
    <div className="hero">
      <div className="button__wrapper-hero">
        <button className="doors-category__go-back" onClick={goBack}>назад</button>
      </div>
      {
        parentCategories?.map(
          item => {
            return (
              <CategoryDoors
                key={item.id}
                name={item.parentCategory}
                category={item.category}
                id={item.id}
                preview={item.preview}
                categoryType={PARENT_CATEGORY_ID}
                pageId=""
              />)
          })
      }
    </div>
  )
}



