import NextButton from "../NextButton/NextButton";
import React from "react";
import './NoResults.css'

export default function NoResults({title, goBack}) {
  return (
    <div className="no-result">
      <h2 className="no-result__title">{title}</h2>
      <NextButton title="Назад" action={goBack}/>
    </div>
  )
}
