export function getValueFromPath(path, target) {
  const regex = new RegExp(`${target}=([^&/]*)`, 'g');
  let lastTargetValue = null;
  let match;

  while ((match = regex.exec(path)) !== null) {
    const targetValue = match[1];
    lastTargetValue = parseInt(targetValue);
  }
  return lastTargetValue;
}
