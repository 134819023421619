import './CollectionList.css'
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {getProductsByCollection} from "../../utils/illumination/api";
import NextButton from "../NextButton/NextButton";
import {InitialProductsContext} from "../../contexts/initialProductsContext";
import Preview from "../Preview/Preview";
import Preloader from '../Preloader/Preloader';

export default function CollectionList({addToBasket, isLoading, setIsLoading}) {
  let listFromUseContext = React.useContext(InitialProductsContext) || []
  const [productList, setProductList] = useState(listFromUseContext)
  const location = useLocation()
  const searchParams = location.pathname
  let {state} = useLocation();
  const navigate = useNavigate();
  const collectionId = state.data.collectionId

  function action() {
    navigate(-1)
  }

  useEffect(_ => {
    setIsLoading(true)
    getProductsByCollection({collectionId})
      .then(setProductList)
      .catch(err => console.error(err))
      .finally(setIsLoading(false))
  }, [collectionId, searchParams])

  return (
    <>
    {
      isLoading ? <Preloader /> :
        <div className="products__category">
          <NextButton title="назад" action={action}/>
          <div className="products__list">
            {productList?.map(el => <Preview key={el.id} id={el.id} cardData={el} addToBasket={addToBasket}/>)
            }
          </div>
        </div>
    }
    </>

  )
}
