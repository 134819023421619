import './App.css';
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import Header from "../Header/Header";
import Navbar from "../Navbar/Navbar";
import React, {useEffect, useState} from "react";
import Hero from "../Hero/Hero";
import Product from "../Product/Product";
import DeliveryPage from '../../pages/DeliveryPage/DeliveryPage';
import ContactsPage from '../../pages/ContactsPage/ContactsPage';
import VendorsPage from '../../pages/VendorsPage/VendorsPage';
import PaymentPage from '../../pages/PaymentPage/PaymentPage';
import {InitialProductsContext} from "../../contexts/initialProductsContext";
import Products from "../Products/Products";
import Collections from "../Collections/Collections";
import CollectionList from "../CollectionList/CollectionList";
import BasketPage from '../../pages/BasketPage/BasketPage';
import SearchList from "../SearchList/SearchList";
import Manufacturers from '../Manufacturers/Manufacturers';
import Filter from "../Filter/Filter";
import CyrillicToTranslit from 'cyrillic-to-translit-js';
import FilterList from "../Filter/FilterList/FilterList";
import {initialFormData} from "../../constants/filter/initialFormData";
import Test from "../Test/Test";
import VendorsCollections from "../VendorsCollections/VendorsCollections";
import DoorsMain from "../../pages/Doors/DoorsMain/DoorsMain";
import DoorsCategoryList from "../AllLevelDoorsCategoryList/DoorsCategoryList/DoorsCategoryList";
import SubDoorsCategoryList from "../AllLevelDoorsCategoryList/SubDoorsCategoryList/SubDoorsCategoryList";
import ProductDoor from "../Product/ProductDoor/ProductDoor";
import ParentDoorsCategoryList from "../AllLevelDoorsCategoryList/ParentDoorsCategoryList/ParentDoorsCategoryList";
import Landing from "../../pages/Landing/Landing";

const cyrillicToTranslit = new CyrillicToTranslit();

function App() {
  const getListFromLocalStorage = JSON.parse(localStorage.getItem('filteredData')) || initialFormData
  const location = useLocation()
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true)
  const [isNavbar, setIsNavbar] = useState(false)
  const [isProcessingCollections, setIsProcessingCollections] = useState(true)
  const [isProcessingCollectionList, setIsProcessingCollectionList] = useState(true)
  const [upt, setUpt] = useState(0)
  const [basketData, setBasketData] = useState('')
  const [allProducts, setAllProducts] = useState([])
  const [formDataForFilter, setFormDataForFilter] = useState(getListFromLocalStorage)
  const [counter, setCounter] = useState(0)
  const [isMobileMode, setIsMobileMode] = useState(false)
  const [mobileNavbarShow, setMobileNavbarShow] = useState(false)
  const [mobileIsFilterOrCategory, setMobileIsFilterOrCategory] = useState('')

  function hideModal() {
    setMobileNavbarShow(false)
    setIsMobileMode(false)
  }

  function showmodal(e) {
    setMobileNavbarShow(true)
    setIsMobileMode(true)
    setMobileIsFilterOrCategory(e.target.id)
  }

  function showFilterModal() {
    setMobileNavbarShow(true)
  }

  function goSearch(e, searchValue) {
    e.preventDefault()
    searchValue.trim()
    if (searchValue === '') {
      return
    }
    const val = cyrillicToTranslit.transform(searchValue, '_').toLowerCase();

    navigate(`search/${val}`)
  }

  const updateBasketData = (newData) => {
    setBasketData(newData);
    localStorage.setItem('basket', JSON.stringify(newData));
  };

  // const addToBasket = (e, name, article, price, image, basketCount, quantity) => {
  //   e.preventDefault()
  //   const newData = Array.from({length: basketCount}, () => ({name, article, price, count: 1, image, quantity}));
  //   setBasketData(prev => [...prev, ...newData])
  // }

  const addToBasket = (e, data, basketCount) => {
    e.preventDefault()
    const newData = Array.from({length: basketCount}, () => (data));
    setBasketData(prev => [...prev, ...newData])
  }



  const handleDeleteItem = (index) => {
    const newData = [...basketData]
    newData.splice(index, 1)
    const newDataJSON = JSON.stringify(newData)
    localStorage.setItem('basket', newDataJSON)
    setBasketData(newData)
  };

  useEffect(() => {
    const storedBasketData = localStorage.getItem('basket');
    if (storedBasketData) {
      setBasketData(JSON.parse(storedBasketData));
    }
  }, [])

  useEffect(() => {
    localStorage.setItem('basket', JSON.stringify(basketData));
  }, [basketData]);

  useEffect(_ => {
    if (
      location.pathname.includes('categories')
      || location.pathname.includes('filterlist')
      || location.pathname.includes('collections')
    ) {
      setIsNavbar(false)
    } else {
      setIsNavbar(true)
    }
  }, [location.pathname])

  return (
    <InitialProductsContext.Provider value={allProducts}>
      <Header
        goSearch={goSearch}
        basketData={basketData}
        hideModal={hideModal}
        show={mobileNavbarShow}
        showmodal={showmodal}
        showFilterModal={showFilterModal}
        mobileIsFilterOrCategory={mobileIsFilterOrCategory}
        counter={counter}
        setCounter={setCounter}
        setFormDataForFilter={setFormDataForFilter}
        setUpt={setUpt}
      />
      <div className="content">
        <main className="main">
          {isNavbar ? <Navbar
              hideModal={hideModal}
              show={mobileNavbarShow}
              showmodal={showmodal}
            /> :
            <Filter
              counter={counter}
              setCounter={setCounter}
              setFormDataForFilter={setFormDataForFilter}
              setUpt={setUpt}
              hideModal={hideModal}
              show={mobileNavbarShow}
              showmodal={showmodal}
            />
          }
          <Routes>
            <Route
              path="/"
              element={
                <Landing
                  addToBasket={addToBasket}
                />
              }/>

            <Route
              path="/illumination"
              element={
                <Hero
                  addToBasket={addToBasket}
                />
              }/>

            <Route
              path="/cards/:id"
              element={
                <Product
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  addToBasket={addToBasket}
                />
              }/>
            {/* Navbar pages */}
            <Route
              path="/delivery"
              element={
                <DeliveryPage/>
              }/>
            <Route
              path="/contacts"
              element={
                <ContactsPage/>
              }/>
            <Route
              path="/vendors"
              element={
                <VendorsPage/>
              }/>
            <Route
              path="/payment"
              element={
                <PaymentPage/>
              }/>
            <Route
              path="/collections/:id"
              element={
                <Collections
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  isProcessingCollections={isProcessingCollections}
                />}
            />
            <Route
              path="/collection/:id/:id"
              element={
                <CollectionList
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  isProcessingCollectionList={isProcessingCollectionList}
                  setIsProcessingCollectionList={setIsProcessingCollectionList}
                  addToBasket={addToBasket}
                />
              }/>
            <Route
              path="/categories/:id"
              element={
                <Products
                  setIsNavbar={setIsNavbar}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  addToBasket={addToBasket}
                />
              }/>
            <Route
              path="/manufacturers/:id"
              element={
                <Manufacturers
                  setIsNavbar={setIsNavbar}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  addToBasket={addToBasket}
                />
              }/>
            <Route
              path="/vendorcollections/:id"
              element={
                <VendorsCollections
                  setIsNavbar={setIsNavbar}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  addToBasket={addToBasket}
                />

              }/>
            <Route
              path="/basket"
              element={
                <BasketPage
                  deleteItem={handleDeleteItem}
                  updateBasketData={updateBasketData}
                />
              }/>
            <Route
              path="/search/:id"
              element={
                <SearchList/>
              }
            />
            <Route
              path="/filterlist/:id"
              element={
                <FilterList
                  formDataForFilter={formDataForFilter}
                  upt={upt}
                  setCounter={setCounter}
                  counter={counter}
                />
              }
            />
            <Route
              path="/test"
              element={
                <Test
                />
              }
            />

            <Route
              path="/doors"
              element={
                <DoorsMain
                  addToBasket={addToBasket}
                />
              }
            />
            <Route
              path="/doors/:parentCategoryId"
              element={
                <ParentDoorsCategoryList
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  addToBasket={addToBasket}
                />
              }
            />
            <Route
              path="/doors/:parentId/:categoryId"
              element={
                <DoorsCategoryList
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  addToBasket={addToBasket}
                />
              }
            />
            <Route
              path="/doors/:parentId/:categoryId/:subcategoryId"
              element={
                <SubDoorsCategoryList
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  addToBasket={addToBasket}
                />
              }
            />
            <Route
              path="doors/product/:productId"
              element={
                <ProductDoor
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  addToBasket={addToBasket}
                />
              }
            />

          </Routes>
        </main>
        {/* <Footer/> */}
      </div>
    </InitialProductsContext.Provider>);
}

export default App;
