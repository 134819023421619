import './CheckboxList.css'
import Checkbox from "../../Checkbox/Checkbox";
import {
  armatureColor,
  colorTemperature,
  lampBaseType,
  lampCoins,
  lampshadeColor,
  land,
  levelOfDefend,
  manufacturer,
  materials,
  placeOfApplication,
  styleList,
  switchLight,
  variantPin,
  voltage
} from "../../../../../constants/filter/checkboxes";

export default function CheckboxList({name, setValueCheckbox, formData, title}) {
  let iterationList, iterationListName
  switch (name) {
    case 'style':
      iterationList = styleList
      iterationListName = 'style'
      break
    case 'placeOfApplication':
      iterationList = placeOfApplication
      iterationListName = 'placeOfApplication'
      break
    case 'voltage':
      iterationList = voltage
      iterationListName = 'voltage'
      break
    case 'lampCoins':
      iterationList = lampCoins
      iterationListName = 'lampCoins'
      break
    case 'lampBaseType':
      iterationList = lampBaseType
      iterationListName = 'lampBaseType'
      break
    case 'colorTemperature':
      iterationList = colorTemperature
      iterationListName = 'colorTemperature'
      break
    case 'materials':
      iterationList = materials
      iterationListName = 'materials'
      break
    case 'levelOfDefend':
      iterationList = levelOfDefend
      iterationListName = 'levelOfDefend'
      break
    case 'armatureColor':
      iterationList = armatureColor
      iterationListName = 'armatureColor'
      break
    case 'lampshadeColor':
      iterationList = lampshadeColor
      iterationListName = 'lampshadeColor'
      break
    case 'switchLight':
      iterationList = switchLight
      iterationListName = 'switchLight'
      break
    case 'variantPin':
      iterationList = variantPin
      iterationListName = 'variantPin'
      break
    case 'manufacturer':
      iterationList = manufacturer
      iterationListName = 'manufacturer'
      break
    case 'land':
      iterationList = land
      iterationListName = 'land'
      break
  }
  return (
    <ul className="list-checkboxes">
      {
        iterationList?.map((el, i) =>
          <Checkbox
            key={i}
            checked={formData.iterationListName}
            onChange={e => setValueCheckbox(e, iterationListName, el, title)}
            name={el}
          />
        )
      }
    </ul>
  )
}
