import Pagination from 'react-bootstrap/Pagination';
import './Pagination.css'
import {useLocation, useNavigate} from "react-router-dom";
import parserPath from "../../utils/parserPath";
import {CATEGORY_ID, NEXT, PAGE_ID, PREV} from "../../constants/constants";

export default function PaginationComp({ active, listLength, path, categoryName }) {
  const pageSize = 30;
  const totalPages = Math.ceil(listLength / pageSize);
  let navigate = useNavigate();
  const location = useLocation();
  const {categoryId} = parserPath(location.pathname)

  function checkActiveMovePage(action) {
    const {pageId} = parserPath(location.pathname)
    switch (action) {
      case NEXT:
        return totalPages <= pageId
      case PREV:
        return pageId <= 1
    }
  }

  function nextPrevMove(action) {
    const {categoryId, pageId} = parserPath(location.pathname)
    switch (action) {
      case NEXT:
        navigate(`/${path}/${categoryName}=${categoryId}&${PAGE_ID}=${+pageId + 1}`)
        break
      case PREV:
        navigate(`/${path}/${categoryName}=${categoryId}&${PAGE_ID}=${+pageId - 1}`)
        break
    }
  }

  function goToPage(pageId) {
    navigate(`/${path}/${categoryName}=${categoryId}&${PAGE_ID}=${pageId}`)
  }
  const renderPaginationItems = () => {
    const items = [];
    const startPage = Math.max(1, active - 2);
    const endPage = Math.min(totalPages, startPage + 4);

    if (startPage > 1) {
      items.push(
        <Pagination.Item key={1} onClick={()=> goToPage('1')}>
          1
        </Pagination.Item>
      );
      if (startPage > 2) {
        items.push(
          <Pagination.Ellipsis key="startEllipsis" disabled />
        );
      }
    }

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item key={number} onClick={()=> goToPage(number)} active={number.toString() === active}>
          {number}
        </Pagination.Item>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        items.push(
          <Pagination.Ellipsis key="endEllipsis" disabled />
        );
      }
      items.push(
        <Pagination.Item key={totalPages} onClick={()=> goToPage(totalPages)}>
          {totalPages}
        </Pagination.Item>
      );
    }
    return items;
  };

  return (
    <Pagination>
      <Pagination.First onClick={()=> goToPage('1')}/>
      <Pagination.Prev disabled={checkActiveMovePage(PREV)} onClick={()=> nextPrevMove(PREV)}/>
      {renderPaginationItems()}
      <Pagination.Next disabled={checkActiveMovePage(NEXT)} onClick={()=> nextPrevMove(NEXT)}/>
      <Pagination.Last onClick={()=> goToPage(totalPages)}/>
    </Pagination>
  );
}
