import './Navbar.css'
import CategoryItem from "../CategoryItem/CategoryItem";
import {navBarList} from "../../constants/navBarList";
import {useLocation} from "react-router-dom";
import {CATEGORY_ID, PAGE_ID} from "../../constants/constants";

export default function Navbar({hideModal, show, showModal}) {
  const location = useLocation()
  if (
    location.pathname === '/' ||
    location.pathname === '/doors' ||
    location.pathname.includes('doors') ||
    location.pathname.includes('illumination')
  ){
    return null
  }
  return (
    <nav className="left-menu">
      {
        navBarList?.map(
          item => {
            return (
              <CategoryItem
                key={item.id}
                name={item.name}
                link={`${CATEGORY_ID}=${item.id}&${PAGE_ID}=1`}
                imageType={item.link}
                hideModal={hideModal}
                show={show}
                showModal={showModal}
              />)
          })
      }
    </nav>
  )
}
