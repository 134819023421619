import './Category.css'
import {Link} from "react-router-dom";
import {converterImageName} from "../../utils/converterImageName";
import {CATEGORIES} from "../../constants/constants";

export default function Category({name, imageType, link}) {
  let image = converterImageName(imageType)
  return (
    <Link to={`/${CATEGORIES}/${link}`} className="parent-category__link">
      <img src={image} alt="menu item" className="parent-category__image"/>
      <p className='parent-category__name'>{name}</p>
    </Link>
  )
}
