import './Landing.css'
import React from "react";
import {CATEGORIES, CATEGORY_ID, PAGE_ID} from "../../constants/constants";
import {pages} from "../../constants/pages";
import Category from "../../components/CategoryItem/Category";
import {Link} from "react-router-dom";

export default function Landing() {
  return (
    <div className="hero">
      {
        pages?.map(
          item => {
            return (
              <Link key={item.id} to={`${item.link}`} className="page__link">
                <img src={item.image} alt="menu item" className="page__image"/>
                <p className='parent-category__name'>{item.name}</p>
              </Link>
            )
          })
      }
    </div>
  )
}



