import React, {useState} from "react"
import {categories} from "../../constants/filter/categories"
import {typeOfLamp} from "../../constants/filter/typeOfLamp"
import AccordionItem from "./AccordionItem"
import {Accordion, Button} from "react-bootstrap"
import Price from "./TypeCategory/AccordionItems/Price/Price"
import Size from "./TypeCategory/AccordionItems/Size/Size"
import CheckboxList from "./TypeCategory/AccordionItems/CheckboxList/CheckboxList"
import {filterCheckboxesOfAccordion} from "../../constants/filter/checkboxes"
import './Filter.css'
import './FilterMobile.css'
import {initialFormData} from "../../constants/filter/initialFormData"
import {useLocation, useNavigate} from "react-router-dom"
import {getSubtype} from "../../utils/illumination/api";
import CyrillicToTranslit from 'cyrillic-to-translit-js';
import Select from "react-select";

const customStyles = {
  container: (provided) => ({
    ...provided, width: '100%',
  }), control: (provided) => ({
    ...provided, border: '1px solid #ffc107', backgroundColor: 'rgba(255,193,7,0.35)', cursor: 'pointer',
  }), indicator: (provided, state) => ({
    ...provided, transform: state.menuIsOpen ? 'rotate(180deg)' : 'none',
  }), option: (provided, state) => ({
    ...provided,
    color: '#000',
    cursor: 'pointer',
    backgroundColor: state.isFocused ? 'rgba(255,193,7,0.35)' : 'inherit',
  }),
}

const cyrillicToTranslit = new CyrillicToTranslit();

export default function FilterMobile({counter, setCounter, setFormDataForFilter, setUpt, hideModal, show, showmodal}) {
  const navigate = useNavigate()
  let {pathname} = useLocation()
  const path = pathname.split('/')[1]

  const [formData, setFormData] = useState(initialFormData)
  const [typeOfFilter, setTypeOfFilter] = useState('categories')
  function setCategory(selectedOption) {
    let newSubtype = []
    getSubtype(selectedOption.id)
      .then(res => {
        res.forEach(el => {
          newSubtype.push({
            id: el.id, label: el.name, value: cyrillicToTranslit.transform(el.name, '_').toLowerCase()
          })
        })
      })
      .then(_ => {
        setFormData((prevData) => ({
          ...prevData, 'category': selectedOption, 'subCategories': newSubtype, 'subCategory': [typeOfLamp],
        }))
      })
  }

  function setValue(event, title, minmax) {
    const {name, value} = event.target
    setFormData((prevData) => ({
        ...prevData,
        [title]: {
          ...prevData[title],
          [minmax]: value
        }
      }
    ))
  }

  function setPrice(event, title, minmax) {
    const {value} = event.target
    setFormData((prevData) => ({
      ...prevData,
      [title]: {
        ...prevData[title],
        [minmax]: value
      }
    }))
  }

  function setValueCheckbox(event, typeEn, value, name) {
    if (name === 'Страна' || name === 'Производитель') {
      setFormData((prevData) => {
        const existingInfo = prevData.manufacturersAndLands.find(info => info[name]);
        if (existingInfo) {
          const existingValues = existingInfo[name];
          const valueIndex = existingValues.indexOf(value);
          if (valueIndex > -1) {
            existingValues.splice(valueIndex, 1);
            if (existingValues.length === 0) {
              const infoIndex = prevData.manufacturersAndLands.indexOf(existingInfo);
              prevData.manufacturersAndLands.splice(infoIndex, 1);
            }
          } else {
            existingValues.push(value);
          }
        } else {
          prevData.manufacturersAndLands.push({[name]: [value]});
        }
        return prevData;
      })
    } else {
      setFormData((prevData) => {
        const existingInfo = prevData.information.find(info => info[name]);
        if (existingInfo) {
          const existingValues = existingInfo[name];
          const valueIndex = existingValues.indexOf(value);
          if (valueIndex > -1) {
            existingValues.splice(valueIndex, 1);
            if (existingValues.length === 0) {
              const infoIndex = prevData.information.indexOf(existingInfo);
              prevData.information.splice(infoIndex, 1);
            }
          } else {
            existingValues.push(value);
          }
        } else {
          prevData.information.push({[name]: [value]});
        }
        return prevData;
      })
    }
  }

  function handleSubmit(event) {
    if (
      formData.subCategory === initialFormData.subCategory
      &&
      formData.categories === initialFormData.categories
      &&
      formData.categories === initialFormData.categories
      &&
      !formData.category
      &&
      formData.regularPrice.min === '' && formData.regularPrice.max === ''
      &&
      formData.diameter.min === '' && formData.diameter.max === ''
      &&
      formData.height.min === '' && formData.height.max === ''
      &&
      formData.length.min === '' && formData.length.max === ''
      &&
      formData.information.length === 0
      &&
      formData.manufacturersAndLands.length === 0
    ) {
      hideModal()
      return null
    }
    setUpt(prev => prev + 1)
    event.preventDefault()
    if(path.includes('categories') || path ===''){
      navigate(`/filterlist/categories`)
      setFormDataForFilter(formData)
    }else if(path.includes('collections')){
      navigate(`/filterlist/collections`)
      setFormDataForFilter(formData)
      // getForCollections(formData)
      //   .then(res => console.log(res))
    }else if(path.includes('vendors')){
      // getForVendors(formData)
    }else if(path.includes('cards')){
      // getForcards(formData)
    }else if(path.includes('filterlist')){
      navigate(`/filterlist/${typeOfFilter}`)
      setFormDataForFilter(formData)
    }
    setCounter(counter + 1)
    hideModal()
  }

  function handleReset() {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]')
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false
    })
    setFormData(initialFormData)
  }

  function setSubCategory(option) {
    setFormData((prevData) => ({
      ...prevData, 'subCategory': option
    }))
  }

  return (
    <div className="left-menu_filter-mobile">
      <div className="select__wrapper">
        <p className="select__title">Тип светильника</p>
        <Select
          className="basic-single"
          classNamePrefix="select"
          value={formData["category"]}
          onChange={setCategory}
          options={formData["categories"]}
          isSearchable={false}
          isClearable={false}
          styles={customStyles}
          defaultValue={formData['categories'][0]}
        />
      </div>
      <div className="select__wrapper">
        <p className="select__title">Вид светильника</p>
        <Select
          className="basic-single"
          classNamePrefix="select"
          value={formData['subCategory']}
          onChange={setSubCategory}
          options={formData['subCategories']}
          isSearchable={false}
          isClearable={false}
          styles={customStyles}
          defaultValue={formData['subCategories'][0]}
        />
      </div>
      <Accordion
        defaultActiveKey={['0']}
        alwaysOpen
      >
        <AccordionItem
          eventKey="0"
          title="Стоимость"
          child={
            <Price
              formData={formData}
              setValue={setPrice}
            />}
        />
        <AccordionItem
          eventKey="1"
          title="Размеры"
          child={
            <Size
              formData={formData}
              setValue={setValue}
            />}
        />
        {/*iteration list*/}
        {filterCheckboxesOfAccordion.map((data, i) => <AccordionItem
          key={i}
          eventKey={i + 2}
          title={data.title}
          child={<CheckboxList
            formData={formData}
            name={data.name}
            title={data.title}
            setValueCheckbox={setValueCheckbox}
          />}
        />)}
      </Accordion>
      <div className="confirm-wrapper">
        <Button
          onClick={handleReset}
          className='confirm-btn'
          size="lg"
          variant="warning">
          Сбросить
        </Button>
        <Button
          onClick={handleSubmit}
          className='confirm-btn'
          size="lg"
          variant="warning">
          Подобрать
        </Button>
      </div>
    </div>
  )
}


