export default function PaymentPage() {
  return (
    <div className="payment__page">
      <div className="payment__title page__title">Оплата</div>
      <div className="payment__content">
        <p><b>Наличными или банковской картой курьеру.</b> Вы рассчитываетесь после доставки и проверки Вашего заказа наличными или банковской картой через мобильный терминал.</p>
        <p><b>Безналичная оплата по счету.</b> Вы можете оплатить выставленный нами счет в любом банке Вашего города или через систему online-банкинга.</p>
      </div>
    </div>
  )
}
