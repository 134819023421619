export const navBarList = [
  {
    id: "1",
    name: "Люстры",
    link: "lyustri",
  },
  {
    id: "2",
    name: "Светильники",
    link: "svetilniki",
  },
  {
    id: "3",
    name: "Бра",
    link: "bra",
  },
  {
    id: "5",
    name: "Торшеры",
    link: "torsheri",
  },
  {
    id: "11",
    name: "Настольные лампы",
    link: "nastolnie_lampi",
  },
  {
    id: "6",
    name: "Трековые и струнные системы",
    link: "trekovie_i_strunnie_sistemi",
  },
  {
    id: "4",
    name: "Споты",
    link: "spoti",
  },
  {
    id: "13",
    name: "Светодиодные ленты",
    link: "svetodiodnie_lenti",
  },
  {
    id: "12",
    name: "Электротовары",
    link: "elektrotovari",
  },
  {
    id: "7",
    name: "Уличные светильники",
    link: "ulichnie_svetilniki",
  },
  {
    id: "8",
    name: "Гирлянды",
    link: "girlyandi",
  },
  {
    id: "9",
    name: "Лампы",
    link: "lampi",
  },
  {
    id: "10",
    name: "Комплектующие",
    link: "komplektuyushchie",
  },
]
