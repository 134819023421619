import './Products.css'
import Preview from "../Preview/Preview"
import React, {useEffect, useState} from "react"
import {getTailProducts} from "../../utils/illumination/api"
import {Link, useLocation} from 'react-router-dom'
import Preloader from '../Preloader/Preloader'
import parserPath from "../../utils/parserPath";
import calcRangeList from "../../utils/calcRangeList";
import PaginationComp from "../Pagination/Pagination";
import {CATEGORIES, CATEGORY_ID, COLLECTIONS, PAGE_ID, VIEW_COLLECTIONS} from "../../constants/constants";

export default function Products({isLoading, setIsLoading, addToBasket}) {
  const [productList, setProductList] = useState([])
  const location = useLocation()
  const {categoryId} = parserPath(location.pathname)
  const [collectionListLength, setCollectionListLength] = useState(0)

  useEffect(_ => {
    setIsLoading(true)
    window.scrollTo(0, 0);
    const {categoryId, pageId} = parserPath(location.pathname)
    let {start, end } = calcRangeList(pageId)
    getTailProducts({start, end, categoryId})
      .then(res => {
        setProductList(res.products)
        setCollectionListLength(res.listLength)
      })
      .finally(() => setTimeout(() => setIsLoading(false), 500))
  }, [location.pathname])

  return (
    <>
      {
        isLoading ? <Preloader/> :
          <div className="products__category">
            <Link to={`/${COLLECTIONS}/${CATEGORY_ID}=${categoryId}&${PAGE_ID}=1`} className="btn btn-warning btn-collection">{VIEW_COLLECTIONS}</Link>
            <div className="products__list">
              {productList?.map((el, i) => {
                return <Preview key={i} id={el.id} cardData={el} addToBasket={addToBasket}/>
              })
              }
            </div>
            <PaginationComp active={parserPath(location.pathname).pageId} listLength={collectionListLength} path={CATEGORIES} categoryName={CATEGORY_ID}/>
          </div>
      }
    </>
  )
}
