import './styles/QuantityStatus.css'

export default function QuantityStatus({quantity}) {
  return (
    <div className="calculator_quanity">
      {
        quantity >= 1 ?
          <span className='calculator_quanity-present'>Товар в наличии</span>
          : <span className='calculator_quanity-absent'>Уточняйте у менеджера</span>
      }
    </div>
  )
}
