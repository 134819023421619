const baseUrl = 'https://miradec.ru/api/'
// const baseUrl = 'https://miradec.ru/test/api/'
// const baseUrl = 'http://localhost:3002/test/api/'
function checkResponse(res) {
  return res.ok ? res.json() : Promise.reject(res);
}

//получить в каталоге категорий
export function getForCategories(formData){

  let {category, information, subCategory, regularPrice, length, diameter, height, manufacturersAndLands} = formData
  let typeFilter = 'category'

  if((regularPrice['min'] === '0' || !regularPrice['min']) && (regularPrice['max'] === '0' || !regularPrice['max'])){
    regularPrice = null
  }
  if((!length['min'] || length.min === '0') && (!length.max || length.max === '0')){
    length = null
  }
  if((!diameter['min'] || diameter['min'] === '0') && (!diameter['max'] || diameter['max'] === '0')){
    diameter = null
  }
  if((!height['min'] || height['min'] === '0') && (!height['max'] || height['max'] === '0')){
    height = null
  }

  if(!information.length){
    information = null
  }
  if (!manufacturersAndLands.length) {
    manufacturersAndLands = null
  }

  if(!category || category?.id === '0'){
    category = null
  }
  return fetch(`${baseUrl}filter/categories`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({category, information, subCategory, regularPrice, length, diameter, height, manufacturersAndLands, typeFilter})
  })
    .then(checkResponse)
}
//получить в каталоге категорий
export function getForcards(formData){
  console.log('formData from Cards')
  console.log(formData)
  return fetch(`${baseUrl}filter/cards`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({formData})
  })
    .then(checkResponse)
}

//получить в каталоге коллекций
export function getForCollections(formData){
  let typeFilter = 'collection'
  let {category, information, subCategory, regularPrice, length, diameter, height, manufacturersAndLands} = formData
  if((regularPrice['min'] === '0' || !regularPrice['min']) && (regularPrice['max'] === '0' || !regularPrice['max'])){
    regularPrice = null
  }
  if((!length['min'] || length.min === '0') && (!length.max || length.max === '0')){
    length = null
  }
  if((!diameter['min'] || diameter['min'] === '0') && (!diameter['max'] || diameter['max'] === '0')){
    diameter = null
  }
  if((!height['min'] || height['min'] === '0') && (!height['max'] || height['max'] === '0')){
    height = null
  }

  if(!information.length){
    information = null
  }
  if (!manufacturersAndLands.length) {
    manufacturersAndLands = null
  }

  if(!category || category?.id === '0'){
    category = null
  }
  return fetch(`${baseUrl}filter/collections`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({category, information, subCategory, regularPrice, length, diameter, height, manufacturersAndLands, typeFilter})
  })
    .then(checkResponse)
}

//получить в каталоге производителей
export function getForVendors(formData){
  console.log('formData from Vendors')
  console.log(formData)
  // return fetch(`${baseUrl}get-child-categories`, {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json'
  //   },
  //   body: JSON.stringify({id})
  // })
  //   .then(checkResponse)
}
